import React, {useState, useContext, useEffect} from 'react';
import DashboardHeadlineCard from './DashboardHeadlineCard';
import MeetingIcon from '../../Images/Dashboard/Meeting.svg';
import CalendarIcon from '../../Images/Dashboard/Calendar.png';
import FollowIcon from '../../Images/Dashboard/Follow.png';
import PlusIcon from '../../Images/Dashboard/Plus.png';
import { Modal } from '@mui/material';
import { LoginContext } from '../../Context/LoginContext';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function DashboardHeadline({userType, userCount, totalMeeting}) {
  const [modal, setModal] = useState(false);
  const [totalMeetings, setTotalMeetings] = useState(0)
  const [filterMeetings, setFilterMeetings] = useState(0)

  const {isLoggedIn, accessToken, login} = useContext(LoginContext);

  const navigate = useNavigate()
  const handleCard = () => {
    try {
      const decodedToken = jwtDecode(accessToken);
      const role = decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];

      if(role === "Admin"){
        const container = document.getElementById("Create-Meeting-Container");
        if (container !==null) {
          container.style.display = "flex";
        }
      }
      else if(role === "User"){
        navigate("/mentors")
      }
    } catch (error) {
      console.log(error);
    }

  };
  
  const handleStats = async() => {
    var decodeToken;
    var userId;
    var role;

    if (accessToken) {
      decodeToken = jwtDecode(accessToken);
      userId = decodeToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];
      role = decodeToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]  
    }

    if(role === "User"){
      try {
        const response = await axios.get(`https://server.mobimenti.co:88/api/Meeting/GetUserMeetings/${userId}`);
        const data = await response.data;

        const currentDate = new Date();
        const filterMeeting = data.filter(item => new Date(item.meetingDate) > currentDate);

        if (response.status === 200) {
          setTotalMeetings(data.length);
          setFilterMeetings(filterMeeting.length);
        }
      } catch (error) {
        console.log(error);
      }
      
    }
    else if(role === "Mentor"){
      try {
        const response = await axios.get(`https://server.mobimenti.co:88/api/Meeting/GetMentorMeetings/${userId}`);
        const data = await response.data;

        const currentDate = new Date();
        const filterMeeting = data.filter(item => new Date(item.meetingDate) > currentDate);

        if (response.status === 200) {
          setTotalMeetings(data.length);
          setFilterMeetings(filterMeeting.length);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  useEffect(() => {
    handleStats();
  }, [])
  

  return (
    <div className='Dashboard-Headline'>

        <DashboardHeadlineCard 
        image={MeetingIcon} 
        title="Toplam Görüşme" 
        value={
          userType === "Admin" ? totalMeeting : totalMeetings
        } 
        text="saat" />
        <DashboardHeadlineCard 
        image={CalendarIcon} 
        title="Planlanmış Görüşme" 
        value={
          userType === "Admin" ? totalMeeting :  filterMeetings
        } 
        text="toplantı" />
        <DashboardHeadlineCard image={FollowIcon} 
        title={
          userType === "Mentor" ? 'Bu kısım üzerinde çalışıyoruz' : userType === "User" ? 'Bu kısım üzerinde çalışıyoruz' : "Toplam Kullanıcı"} 
          value={userCount} 
          text={userType === "Mentor" ? 'menti' : userType==="User" ? 'mentor' : 'kullanıcı'} />
        <DashboardHeadlineCard 
        onClick={handleCard}
        image={PlusIcon} 
        title={userType === "Mentor" ? 'Bu kısım üzerinde çalışıyoruz' : userType==="User" ? 'Hemen Randevu Al' : 'Randevu Oluştur'} 
        value={userType === "Mentor" ? '' : ''} text="" />
    </div>
  )
}

export default DashboardHeadline