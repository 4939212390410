import React from 'react'

function DashboardMeetingMiniCard({image, name, date, status}) {
  return (
    <div className='Dashboard-Meeting-MiniCard'>
        <div className="Dashboard-Meeting-MiniCard-Image">
          <img src={image} alt="" />
        </div>
        <div className="Dashboard-Meeting-MiniCard-Name">
          <p>{name}</p>
        </div>
        <div className="Dashboard-Meeting-MiniCard-Date">
          <p>{date}</p>
        </div>
        <div className="Dashboard-Meeting-MiniCard-Status">
          <p>{status}</p>
        </div>
    </div>
  )
}

export default DashboardMeetingMiniCard