import React, {useState, useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import {Swiper, SwiperSlide} from 'swiper/react';
import { Navigation } from 'swiper/modules';
import ProfilePhoto from '../../Images/HomePage/Profile.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../../Style/HomePage.css';
import 'swiper/css';
import 'swiper/css/navigation';
import axios from 'axios';
import { Button, Modal } from '@mui/material';
import { LoginContext } from '../../Context/LoginContext';
import { jwtDecode } from 'jwt-decode';




function MentorSlider() {
    const [mentors, setMentors] = useState([]);
    const [mentorModal, setMentorModal] = useState(false);
    const [selectedMentor, setSelectedMentor] = useState(null);

    const{isLoggedIn, accessToken} = useContext(LoginContext);

    const navigate = useNavigate();

    const getMentors  = async () => {
        try {
            const response = await axios.get('https://server.mobimenti.co:80/api/Mentor', {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
            });
            const data = response.data;
            setMentors(data);
        } catch (error) {
            console.error('Hata oluştu:', error);
        }

    }
    const handleSelectedMentor = (mentor) => {
        setSelectedMentor(mentor);
        setMentorModal(true);
    }

    const handleModalClose = () => setMentorModal(false);

    const setMeeting = async (mentorId) => {
        if(isLoggedIn){
            const decodeToken = jwtDecode(accessToken);
            const userId = decodeToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];

            try {
                const response = await axios.post("https://server.mobimenti.co:88/api/MeetingRequest",{
                    userId:userId,
                    mentorId:mentorId,
                    status:"Bekliyor"
                });
                if(response.status === 200){
                    toast.success("İsteğiniz başarıyla oluşturuldu");
                    handleModalClose();
                }
            } catch (error) {
                
            }
        }
        else{
            navigate("/login")
        }
    }

    useEffect(() => {
        getMentors();
    },[])
  return (
    <div className='Mentor-Slider'>
        <Swiper
            slidesPerView={2}
            spaceBetween={30}
            navigation={true}
            modules={[Navigation]}
            className="Mentor-Swiper"
            breakpoints={{
                490: {
                    slidesPerView: 3,
                },
                860: {
                    slidesPerView: 4,
                },
                1300: {
                    slidesPerView: 6,
                }
            }}    
        >
            
            {mentors && mentors.map((item, key) => (
                item.confirmed && (
                    <>
                        <SwiperSlide 
                        className='Mentor-Slider-Card' 
                        key={item.id}
                        onClick={() => handleSelectedMentor(item)}
                        >
                            <div className="Profile-Photo">
                                {item.imageData ? (
                                    <img src={`data:image/jpeg;base64,${item.imageData}`} alt="" 
                                    style={{ width: "100px", height:"100px", objectFit:"cover", borderRadius:"50%" }} />
                                ): (
                                    <img src={ProfilePhoto} alt="" 
                                    style={{ width: "100px", height:"100px", objectFit:"cover", borderRadius:"50%" }} />
                                )}
                            </div>
                            <div className="Mentor-Information">
                                <div className="Mentor-Name">
                                <strong><p>{item.name} {item.surname}</p></strong>
                                </div>
                                <div className="Mentor-Job">
                                    <p>{item.job}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    </>
                )
                    
            ))}
        </Swiper>
        {selectedMentor && (
            <Modal open={mentorModal} onClose={handleModalClose}>
                <div className='Mentor-Slider-Modal'>
                    <div className="Profile-Photo">
                        {selectedMentor.imageData && (
                            <img src={`data:image/jpeg;base64,${selectedMentor.imageData}`} alt="" 
                            style={{ width: "100px", height:"100px", objectFit:"cover", borderRadius:"50%" }} />
                        )}
                    </div>
                    <div className="Mentor-Information">
                        <div className="Mentor-Name">
                            <strong><p>{selectedMentor.name} {selectedMentor.surname}</p></strong>
                        </div>
                        <div className="Mentor-Job">
                            <p>{selectedMentor.job}</p>
                        </div>
                        <div className="Mentor-Meeting-Btn">
                            <Button onClick={() => {setMeeting(selectedMentor.id)}} variant='outlined' >Randevu Talep Et</Button>
                        </div>
                    </div>
                    
                </div>
            </Modal>
            )}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
    </div>
  )
}

export default MentorSlider