import React from 'react';
import DashboardMessagesMiniCard from './DashboardMessagesMiniCard';
import ProfileIcon from '../../Images/Dashboard/Profile.png';

function DashboardMessagesComponent() {
  return (
    <div className='Dashboard-Messages-Component'>
      <div className="Dashboard-Messages-Component-Title">
        <p>Mesajlar</p>
      </div>
      <div className="Dashboard-Messages-List">
        {/* <DashboardMessagesMiniCard image={ProfileIcon} name="Fatih Demirbaş" message="Merhaba. Konuştuğumuz hedefleri tamamladım görüşebilir miyiz?" />
        <DashboardMessagesMiniCard image={ProfileIcon} name="Güneş Adıgüzel" message="Merhaba. Konuştuğumuz hedefleri tamamladım görüşebilir miyiz?" />
        <DashboardMessagesMiniCard image={ProfileIcon} name="Ece Dirhemsiz" message="Merhaba. Konuştuğumuz hedefleri tamamladım görüşebilir miyiz?" /> */}
        Bu kısım üzerinde çalışıyoruz
      </div>
    </div>
  )
}

export default DashboardMessagesComponent