import React, {createContext, useEffect, useState} from "react";

const LoginContext = createContext();

const LoginProvider = ({children}) => {
    const [accessToken, setAccessToken] = useState(() => {
        return localStorage.getItem('accessToken') || null;
    });

    const isLoggedIn = !!accessToken;

    const login = (token) =>{
        localStorage.setItem('accessToken', token);
        setAccessToken(token);
    }

    const logout=() =>{
        localStorage.removeItem('accessToken');
        setAccessToken(null);
    }

   useEffect(() => {
     localStorage.setItem('accessToken', accessToken || '');
   }, [accessToken]);
   

    return(
        <LoginContext.Provider value={{isLoggedIn, login, logout, accessToken}}>
            {children}
        </LoginContext.Provider>
    )
}

export{LoginContext, LoginProvider};