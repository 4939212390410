import React,{useState} from 'react'
import { Link } from 'react-router-dom';
import { TextField, Input, InputLabel, FormControl, InputAdornment, IconButton, FormHelperText, Modal } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import GoogleLogo from '../../Images/AuthPage/Logo-1.png'
import AppleLogo from '../../Images/AuthPage/Logo.png'
import FacebookLogo from '../../Images/AuthPage/Logo-2.png'
import SuccessIcon from '../../Images/RegisterPage/SuccessIcon.svg'
import { Resend } from 'resend';
import WelcomeUser from '../../Emails/WelcomeUser';

import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

function MenteeRegisterComponent() {

    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    
  
    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState(false);
    const [helperText, setHelperText] = useState("")
  
    const [name, setName] = useState("")
    const [nameError, setNameError] = useState(false)
    const [nameHelperText, setNameHelperText] = useState("")
  
    const [surname, setSurname] = useState("")
    const [surnameError, setSurnameError] = useState(false)
    const [surnameHelperText, setSurnameHelperText] = useState("")
  
    const [password, setPassword] = useState("")
    const [passwordError, setPasswordError] = useState(false)
    const [passwordHelperText, setPasswordHelperText] = useState("")
  
    const [confirmPassword, setConfirmPassword] = useState("")
    const [confirmPasswordError, setConfirmPasswordError] = useState(false)
    const [confirmPasswordHelperText, setConfirmPasswordHelperText] = useState("")

    const [openModal, setOpenModal] = useState(false)

    const resend = new Resend('re_coccwQc6_NgJ9tPMQFY4uwMvxxgudoJeu');
  
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowPasswordConfirm = () => {
      setShowPasswordConfirm(!showPasswordConfirm)
    }
  
    const handleMouseDownPassword = (e) => {
      e.preventDefault();
    };
  
    const emailValidation = (e) => {
      e.preventDefault()
      const regex = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z]{2,8})?/g;
      const emailBox = document.getElementById("mentor-register-email");
    
      if(email !== ""){
        if (!regex.test(email)) {
  
          setHelperText("Lütfen geçerli bir e-posta adresi giriniz.")
          setEmailError(true);
          
        } else {
    
          emailBox.setAttribute("helperText", "");
        }
      }
      else{
        setHelperText("E-posta alanı boş geçilemez.")
        setEmailError(true)
      }
  
    };
  
    const fieldValidation = (e) => {
      e.preventDefault();
      
      if(name === ""){
        setNameError(true);
        setNameHelperText("İsim alanı boş geçilemez");
      }
      else{
        setNameHelperText("");
      }
  
      if(surname === ""){
        setSurnameError(true);
        setSurnameHelperText("Soyisim alanı boş geçilemez");
      }
      else{
        setSurnameHelperText("");
      }
  
    }
  
    const passwordValidation = (e) => {
      e.preventDefault();
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
      const passwordHelperText = (
        <div>
          <div>Şifre şunları içermelidir:</div>
          <div>- En az 8 karakter</div>
          <div>- En az bir büyük harf</div>
          <div>- En az bir küçük harf</div>
          <div>- En az bir sayı</div>
        </div>
      );
  
      if(!passwordRegex.test(password)){
        setPasswordHelperText(passwordHelperText);
        setPasswordError(true)
      }
      else{
        setPasswordHelperText(""); 
        setPasswordError(false); 
      }
    }
  
    const confirmPasswordValidation = (e) => {
      e.preventDefault();
      if(confirmPassword !== password){
        setConfirmPasswordHelperText("Şifreler eşleşmiyor")
        setConfirmPasswordError(true)
      }
      else{
        setConfirmPasswordHelperText("")
        setConfirmPasswordError(false)
      }
    }

    const handleSubmit = async (e) => {
      e.preventDefault()
      
      try {
        const response = await axios.post('https://server.mobimenti.co:80/api/UserAuth/register',{
          name: name,
          surname: surname,
          email: email,
          password: password,
        });
  
        if (response.status === 200) {
          setOpenModal(true)
          await resend.emails.send({
            from: 'info@mobimenti.co',
            to: 'demirbasfatih71.fd@gmail.com',
            subject: 'Hoşgeldin',
            html: 'Welcome User'
          })
        }
      } catch (error) {
        console.log("Kayıt Hatası:", error);
      }
    }

  //   const sendEmail = async (email) => {
  //     try {
  //       const response = await axios.post("https://api.resend.com/emails", {
  //           "from": "info@mobimenti.co",
  //           "to": "demirbasfatih71.fd@gmail.com",
  //           "subject": "Hoşgeldin",
  //           "html": "WelcomeUser"
  //       }, {
  //           headers: {
  //               'Content-Type': 'application/json',
  //               'Authorization': `Bearer re_coccwQc6_NgJ9tPMQFY4uwMvxxgudoJeu`
  //           }
  //       });


  //       console.log(response);
  //   } catch (error) {
  //       console.log(error);
  //   }
  // }
  

  return (
    <div className='Mentee-Register-Component'>
      <div className="Register-Form">
        <div className="Register-Text-Box">
          <h1 style={{fontWeight:"lighter"}}>Hoşgeldin</h1><br />
          <p style={{fontWeight:"lighter", fontSize:"12px"}}>Gençlerin kariyer yolculuğunu destekleyen bir mentor ağı oluşturuyoruz.</p><br />
          <p style={{fontWeight:"lighter", fontSize:"12px"}}>Güçlü bir kariyer inşa etmek için Mobimenti’ye katıl.</p>
        </div>
        <div className="Form">
          <h1 style={{color:"#7978FF",fontWeight:"400"}}>Kaydol</h1>
          <div className="Sign-Options">
            <img src={GoogleLogo} className='Sign-Options-Icon' alt="" style={{width:"20px", margin:'0 10px'}} />
            <img src={AppleLogo} className='Sign-Options-Icon' alt="" style={{width:"20px", margin:'0 10px'}} />
            <img src={FacebookLogo} className='Sign-Options-Icon' alt="" style={{width:"15px", margin:'0 10px'}} />
          </div>
        
          <div className="Form-Body">
            <form>
              <div className="Register-Name-Field">
                <TextField 
                  label="İsim"
                  variant='standard'
                  autoComplete='name'
                  sx={{ m: 1, width:"100%"}}
                  required
                  error={nameError}
                  helperText={nameHelperText}
                  onChange={(e) => setName(e.target.value)}
                />
                <TextField 
                  label="Soyisim"
                  variant='standard'
                  autoComplete='name'
                  sx={{ m: 1, width:"100%"}}
                  required
                  error={surnameError}
                  helperText={surnameHelperText}
                  onChange={(e) => setSurname(e.target.value)}
                />
              </div>
              <TextField
                id='mentor-register-email' 
                label="Email"
                variant='standard'
                autoComplete='email'
                sx={{ m: 1, width:"100%"}}
                onChange={(e) => {setEmail(e.target.value)}}
                error={emailError}
                helperText={helperText}
                required
              />

              <FormControl sx={{ m: 1, width:"100%"}} variant="standard" required>
                <InputLabel htmlFor="mentor-standard-adornment-password">Şifre</InputLabel>
                <Input
                  id="mentor-standard-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                 onChange={(e) => {setPassword(e.target.value)}}
                 error={passwordError}
                 helperText = {passwordHelperText}
                />
                {passwordError && (
                  <FormHelperText>{passwordHelperText}</FormHelperText>
                )}
              </FormControl>

              <FormControl sx={{ m: 1, width:"100%"}} variant="standard" required>
                <InputLabel htmlFor="mentor-standard-password-confirm">Şifre (Tekrar)</InputLabel>
                <Input
                  id="mentor-standard-password-confirm"
                  type={showPasswordConfirm ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPasswordConfirm}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  onChange={(e) => {setConfirmPassword(e.target.value)}}
                  error={confirmPasswordError}
                />
                {confirmPasswordError && (
                  <FormHelperText>{confirmPasswordHelperText}</FormHelperText>
                )}
              </FormControl>
              <div className="Form-Bottom">
                <button type="submit" id='Mentor-Register-Form-Button' 
                onClick={(e) => {
                  emailValidation(e);
                  fieldValidation(e);
                  passwordValidation(e);
                  confirmPasswordValidation(e);
                  handleSubmit(e)
                }}>Kaydol</button>
                <div className="Register-Line">
                  <div className="first-line"></div>
                  <p>Ya da</p>
                  <div className="second-line"></div>
                </div>
                <div className="Mobile-Sign-Options">
                  <img src={GoogleLogo} className='Sign-Options-Icon' alt="" style={{margin:'0 10px'}} />
                  <img src={AppleLogo} className='Sign-Options-Icon' alt="" style={{margin:'0 10px'}} />
                  <img src={FacebookLogo} className='Sign-Options-Icon' alt="" style={{margin:'0 10px'}} />
                </div>
                <p style={{fontSize:"12px"}}>Zaten hesabın var mı? &nbsp;<Link to='/login'><strong>Giriş Yap</strong></Link></p>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal
        open={openModal}
      >
        <div className="Modal-Content">
          <div className="Modal-Image">
            <img src={SuccessIcon} alt="" />
          </div>
          <div className="Modal-Text">
            <h1>İşlem Başarılı</h1>
            <h4>Giriş Yapabilirsiniz</h4>
            <Link to="/login"><button>Giriş Yap</button></Link>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default MenteeRegisterComponent