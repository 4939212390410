import React, {useState, useContext, useEffect} from 'react';
import dayjs from 'dayjs';
import Badge from '@mui/material/Badge';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import { LoginContext } from '../../Context/LoginContext';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

const initialValue = dayjs('2022-04-17');

// Örnek toplantılar

function ServerDay(props) {
  const { meetings = [], day, outsideCurrentMonth, ...other } = props;

  return (
    <Badge
      key={day.toString()}
      overlap="circular"
      badgeContent={meetings.map(meeting => day.isSame(meeting.date, 'day') && "🌚")}
    >
      <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
    </Badge>
  );
}

export default function DateCalendarWithMeetings() {
  const [meetings, setMeetings] = useState([])

  const {accessToken} = useContext(LoginContext);

  const getMeetings = async () => {
    var decodeToken = "";
    var userId = "";

    if (accessToken) {
      decodeToken = jwtDecode(accessToken);
      userId = decodeToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];
    }

    try {
      const response = await axios.get(`https://server.mobimenti.co:88/api/Meeting/GetUserMeetings/${userId}`);
      if(response.status === 200){
        setMeetings(response.data)
        
      }
    } catch (error) {
     console.log(error); 
    }
  }

  useEffect(() => {
    getMeetings();
  }, [])
  

  return (
    <div className="Dashboard-Calendar-Component">
      <div className="Dashboard-Calendar-Component-Title">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            defaultValue={initialValue}
            renderLoading={() => <DayCalendarSkeleton />}
            slots={{
              day: ServerDay,
            }}
            slotProps={{
              day: {
                meetings: meetings,
              },
            }}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
}
