import React, {useState, useContext} from 'react'
import { useNavigate } from 'react-router-dom';
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { Box, Grid, Modal, Button } from '@mui/material'
import axios from 'axios';
import { LoginContext } from '../Context/LoginContext';
import { jwtDecode } from 'jwt-decode';
import { ToastContainer, toast } from 'react-toastify';
import ProfilePhoto from '../Images/HomePage/Profile.png'
import '../Style/AllMentors.css'

function AllMentors() {
  const [mentors, setMentors] = useState([]);
  const [mentorModal, setMentorModal] = useState(false);
  const [selectedMentor, setSelectedMentor] = useState(null)
  const{isLoggedIn, accessToken} = useContext(LoginContext);
  

  const navigate = useNavigate();

  const getMentors  = async () => {
    try {
        const response = await axios.get('https://server.mobimenti.co:80/api/Mentor', {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
        });
        const data = response.data;
        setMentors(data);
    } catch (error) {
        console.error('Hata oluştu:', error);
    }

}

const handleSelectedMentor = (mentor) => {
  setSelectedMentor(mentor);
  setMentorModal(true);
}

const handleModalClose = () => setMentorModal(false);

const setMeeting = async (mentorId) => {
  if(isLoggedIn){
      const decodeToken = jwtDecode(accessToken);
      const userId = decodeToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];

      try {
          const response = await axios.post("https://server.mobimenti.co:88/api/MeetingRequest",{
              userId:userId,
              mentorId:mentorId,
              status:"Bekliyor"
          });
          
          if(response.status === 200){
              toast.success("İsteğiniz başarıyla oluşturuldu");
              handleModalClose();
          }
      } catch (error) {
          
      }
  }
  else{
      navigate("/login")
  }
}

  return (
    <div onLoad={getMentors}>
      <header>
        <Navbar/>
      </header>
      <div>
        <Grid container >
          <Grid className='Filter-Container' padding={1} borderRight={1} borderColor={"var(--default-font-color-light)"} xs={2}>
          {/* <b><p>Filtreler</p></b> */}
          </Grid>
          <Grid container display="flex" wrap='wrap' gap={2} sx={{width:"100%"}} padding={2} xs={10} className='All-Mentors-Container'>
            {mentors && mentors.map((item, key)=>(
              item.confirmed && (
                <>
                  <Box className="All-Mentors-Cards" onClick={() => {handleSelectedMentor(item)}} display="flex" flexWrap="wrap" flexDirection="column" alignItems="center" justifyContent="center" padding={2} height={250} >
                    <div className="Profile-Photo">
                      {item.imageData ? (
                          <img src={`data:image/jpeg;base64,${item.imageData}`} alt="" 
                          style={{ width: "100px", height:"100px", objectFit:"cover", borderRadius:"50%" }} />
                      ): (
                          <img src={ProfilePhoto} alt="" 
                          style={{ width: "100px", height:"100px", objectFit:"cover", borderRadius:"50%" }} />
                      )}
                    </div>
                    <div className="Mentor-Information">
                        <div className="Mentor-Name">
                        <strong><p>{item.name} {item.surname}</p></strong>
                        </div>
                        <div className="Mentor-Job">
                            <p>{item.job}</p>
                        </div>
                    </div>
                  </Box>
                </>
              )
            ))}
          </Grid>
          {selectedMentor && (
            <Modal open={mentorModal} onClose={handleModalClose}>
            <div className='Mentor-Slider-Modal'>
                <div className="Profile-Photo">
                    {selectedMentor.imageData && (
                        <img src={`data:image/jpeg;base64,${selectedMentor.imageData}`} alt="" 
                        style={{ width: "100px", height:"100px", objectFit:"cover", borderRadius:"50%" }} />
                    )}
                </div>
                <div className="Mentor-Information">
                    <div className="Mentor-Name">
                        <strong><p>{selectedMentor.name} {selectedMentor.surname}</p></strong>
                    </div>
                    <div className="Mentor-Job">
                        <p>{selectedMentor.job}</p>
                    </div>
                    <div className="Mentor-Meeting-Btn">
                        <Button onClick={() => {setMeeting(selectedMentor.id)}} variant='outlined' >Randevu Talep Et</Button>
                    </div>
                </div>
                
            </div>
        </Modal>
          )}
        </Grid>
      </div>
      <footer>
        <Footer/>
      </footer>
      <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
    </div>
  )
}

export default AllMentors