import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { Grid, Typography } from '@mui/material'
import { Email, Instagram, LinkedIn } from '@mui/icons-material'
import ContactImage from '../Images/Contact/contact.jpg'
import { Link } from 'react-router-dom'

function Contact() {
  return (
    <div>
        <header>
            <Navbar/>
        </header>
        <div>
            <Grid display="flex" container>
                <Grid sm={6} xs={12}  display="flex" alignItems="center" justifyContent="center" padding={2}>
                    {/* <img width={400} height={400} src={ContactImage} alt="" /> */}
                    <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3012.452518500121!2d29.0400746109561!3d40.97157097123623!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1zRmVuZXJiYWjDp2UgTWguIEnEn3LEsXAgU2suIE5vOiAxMyBLYWTEsWvDtnkvxLBzdGFuYnVswqBQSzrCoDM0NzI2!5e0!3m2!1str!2str!4v1709322430014!5m2!1str!2str" width="400" height="400" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </Grid>
                <Grid sm={6} xs={12} display="flex" flexDirection="column" justifyContent="center" padding={2}>
                    <Grid xs={12} display="flex" flexDirection="column" justifyContent="center"><Typography variant='h4' >Bize Ulaşın</Typography><br />
                    <div style={{display:"flex", alignItems:"center"}}><Email/>&nbsp; info@mobimenti.co</div><br />
                    <div>
                        <Link target='blank' to="https://www.instagram.com/mobimenti.co/"><Instagram fontSize='large' sx={{color:"fuchsia"}} /></Link>
                        <Link target='blank' to="https://www.linkedin.com/company/mobi̇menti̇"><LinkedIn fontSize='large' sx={{color:"darkblue"}}/></Link>
                    </div>
                    
                    <div>
                        <b>Adres: </b><p>Fenerbahçe Mh. Iğrıp Sk. No: 13,<br /> Kadıköy/İstanbul PK: 34726</p>
                    </div>
                    </Grid>
                    {/* <Grid>
                    </Grid> */}
                </Grid>
            </Grid>
        </div>
        <footer>
            <Footer/>
        </footer>
    </div>
  )
}

export default Contact