import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import HomePage from './Pages/HomePage';
import AuthPage from './Pages/AuthPage';
import RegisterPage from './Pages/RegisterPage';
import MenteeDashboard from './Pages/MenteeDashboard';
import MentorDashboard from './Pages/MentorDashboard';
import AdminDashboard from './Pages/AdminDashboard';
import AllMentors from './Pages/AllMentors';
import AboutUs from './Pages/AboutUs';

import { LoginProvider } from './Context/LoginContext';
import WelcomeEmail from './Emails/WelcomeUser';
import Contact from './Pages/Contact';



function App() {
  return (
    <>
    <div className="App">
      <header className="App-header">
        
      </header>
      <main>
        <LoginProvider>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/login' element={<AuthPage />} />
        <Route path='/register' element={<RegisterPage />} />
        <Route path='/mentee-dashboard' element={<MenteeDashboard />} />
        <Route path='/mentor-dashboard' element={<MentorDashboard />} />
        {/* <Route path='/additional-information' element={<AdditionalInformation />} /> */}
        <Route path='/admin' element={<AdminDashboard />} />
        <Route path='/mentors' element={<AllMentors/>} />
        <Route path='/about-us' element={<AboutUs/>} />
        <Route path='/contact-us' element={<Contact/>} />

        <Route path='/email' element={<WelcomeEmail/>} />
      </Routes>
      </LoginProvider>
      </main>
    </div>
    </>
  );
}

export default App;
