import React, {useEffect, useState} from 'react'
import { DataGrid } from '@mui/x-data-grid'
import axios from 'axios';
import { Button, Typography } from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function formatReadableDate(dateString) {
  const options = { year: 'numeric', month: 'long', day: 'numeric'};
  const formattedDate = new Intl.DateTimeFormat('tr-TR', options).format(new Date(dateString));
  return formattedDate;
}

function UserList() {
    const [mentors, setMentors] = useState([]);

    const columns = [
      {
        field: 'id',
        headerName: "ID",
        sortable: true,
        
      },
      {
        field: 'name',
        headerName: "İsim",
        sortable: true,
        flex: 1,
      },
      {
        field: 'surname',
        headerName: "Soyisim",
        sortable: true,
        flex: 1,
      },
      {
        field: 'email',
        headerName: "Email",
        flex: 1,
        maxWidth:300
      },
      {
        field: 'created',
        headerName: "Kayıt Tarihi",
        sortable: true,
        flex: 1,
        renderCell: (params) => (
          <span>{formatReadableDate(params.value)}</span>
        )
      },

      {
        field: 'confirmed',
        headerName: "Durum",
        sortable: false,
        flex: 1,
        renderCell: (params) => (
          params.row.confirmed ? 
          (<span style={{color:"green"}}>Onaylandı</span>) : 
          (
            <>
            <span style={{color:"orange"}}>Bekliyor..</span>
            <Button variant='contained' color='success' style={{margin:"0 10px"}} onClick={() => handleConfirm(params.row.id)} ><Check style={{color:"white"}} /></Button>
            <Button variant='contained' color='error' onClick={() => {handleDelete(params.row.id)}} ><Close style={{color:"white"}} /></Button>
            </>
          )
        ),
      },
    ]

    const getMentors = async () => {
        const response = await axios.get('https://server.mobimenti.co:80/api/Mentor');
        const userData = response.data;
  

        if(response.status === 200){
          const confirmedMentors = userData.filter(mentor => mentor.confirmed === false);
          setMentors(confirmedMentors);
        }
        
    }

    const handleConfirm = async (mentorId) => {
      try {
        const response = await axios.put(`https://server.mobimenti.co:80/api/Mentor/${mentorId}`,{
          confirmed: true,
        });
        if(response.status === 200){
          toast.success("İstek Kabul Edildi");
          getMentors();
        }
      } catch (error) {
        console.log(error);
      }

    }

    const handleDelete = async (mentorId) => {
      try {
        const response = await axios.delete(`https://server.mobimenti.co:80/api/Mentor/${mentorId}`);
        if(response.status === 200){
          toast.success("İstek Silindi.");
          getMentors();
        }
      } catch (error) {
        console.log(error);
      }
    }

    useEffect(() => {
      getMentors();
    }, [])
    

  return (
    <div className='Admin-User-List'>
      <h1>Mentor Başvuruları</h1>
          {mentors && (
              <DataGrid 
              rows={mentors} 
              columns={columns} 
              style={{backgroundColor:"white", width:"100%", height:"auto"}} 
              checkboxSelection 
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 15,
                  },
                },
              }}
              pageSizeOptions={[15]}
              disableRowSelectionOnClick
              />
          )}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
    </div>
  )
}

export default UserList