import React from 'react'

function DashboardHeadlineCard({image, title, value, text, onClick}) {
  return (
    <div className='Dashboard-Headline-Card' onClick={onClick}>
      <div className="Dashboard-Headline-Card-Icon-Container">
        <img src={image} alt="" />
      </div>
      <div className="Dashboard-Headline-Card-Text-Container">
        <dl>
          <dt className="Dashboard-Headline-Card-Title">{title}</dt>
          <dd className="Dashboard-Headline-Card-Value">{value} <span>{text}</span></dd>
        </dl>
      </div>
    </div>
  )
}

export default DashboardHeadlineCard