import React,{useState, useContext, useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { TextField, Input, InputLabel, FormControl, InputAdornment, IconButton, CircularProgress, Alert } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import GoogleLogo from '../../Images/AuthPage/Logo-1.png'
import AppleLogo from '../../Images/AuthPage/Logo.png'
import FacebookLogo from '../../Images/AuthPage/Logo-2.png'

import axios from 'axios';
import { LoginContext } from '../../Context/LoginContext';
import { jwtDecode } from 'jwt-decode';

function MentorLoginComponent() {
  const [showPassword, setShowPassword] = useState(false);

  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState(false);

  const [password, setPassword] = useState("")

  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(null);

  const navigate = useNavigate();
  const {login} = useContext(LoginContext);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const emailValidation = () => {
    const regex = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z]{2,8})?/g;
    const emailBox = document.getElementById("mentor-sign-email");
  
    if (!regex.test(email)) {

      emailBox.setAttribute("helperText", "Lütfen geçerli bir e-posta giriniz!");

      setEmailError(true);
    } else {

      emailBox.setAttribute("helperText", "");
    }
  };

  const handleLogin = async () => {
    try {
      setLoading(true)
      const response = await axios.post('https://server.mobimenti.co:80/api/MentorAuth/login', {
        email: email,
        password: password
      });
      
      if(response.status === 200){
        setLoading(false)
        const accessToken = response.data;
        login(accessToken);

        //Access Token'ı çöz
        const decodedToken = jwtDecode(accessToken);
        const expiration = decodedToken.exp;
        const userId = decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];
        const currentTime = Math.floor(Date.now() / 1000);

        if(expiration < currentTime){
          await refreshAccessToken(userId);
        }


        navigate('/mentor-dashboard');

      }
    } catch (error) {
      console.log(error);
      setLoginError(error.response.data);
    } finally {
      setLoading(false);
    }
  }

  const refreshAccessToken = (id) => {
    const response = axios.post(`https://server.mobimenti.co:80/api/RefreshToken/refresh-token/${id}`);

    if(response.status === 200){
      login(response.data);
    }
  }
  

  return (
    loading ? (
      <CircularProgress />
    ):(
<div className='Login-Component'>
      <div className="Sign-Form">
        <div className="Form">
          <h1 id='Mentor-Form-Header' style={{color:"#7978FF",fontWeight:"400"}}>Giriş Yap</h1>
          <div className="Sign-Options">
            <img src={GoogleLogo} className='Sign-Options-Icon' alt="" style={{width:"20px", margin:'0 10px'}} />
            <img src={AppleLogo} className='Sign-Options-Icon' alt="" style={{width:"20px", margin:'0 10px'}} />
            <img src={FacebookLogo} className='Sign-Options-Icon' alt="" style={{width:"15px", margin:'0 10px'}} />
          </div>
          <div className="Form-Body">
            <form>
            <TextField
              id="mentor-sign-email"
              label="E-Posta Adresi"
              variant="standard"
              autoComplete='email'
              sx={{ m: 1}}
              onChange={(e) => {setEmail(e.target.value)}}
              error={emailError}
              helperText={emailError ? "Lütfen geçerli bir e-posta giriniz!" : ""}
            />
            <FormControl sx={{ m: 1}} variant="standard">
              <InputLabel htmlFor="mentor-standard-adornment-password">Password</InputLabel>
              <Input
                id="mentor-standard-adornment-password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                onChange={(e) => {setPassword(e.target.value)}}
              />
            </FormControl>
            {loginError && (
              <Alert severity='error'>{loginError}</Alert>
            )}
            </form>
            <div className="Form-Bottom">
              <button 
              type="submit" 
              id='Mentor-Sign-Form-Button' 
              onClick={(e) => {
                emailValidation();
                handleLogin();
              }}>Giriş Yap</button>
              <div className="Login-Line">
                <div className="first-line"></div>
                <p>Ya da</p>
                <div className="second-line"></div>
              </div>
              <div className="Mobile-Sign-Options">
                <img src={GoogleLogo} className='Sign-Options-Icon' alt="" style={{margin:'0 10px'}} />
                <img src={AppleLogo} className='Sign-Options-Icon' alt="" style={{margin:'0 10px'}} />
                <img src={FacebookLogo} className='Sign-Options-Icon' alt="" style={{margin:'0 10px'}} />
              </div>
              <p style={{fontSize:"12px"}}>Henüz hesabın yok mu? &nbsp;<Link to="/register"><strong>Kaydol</strong></Link></p>
            </div>
          </div>
        </div>
        <div className="Sign-Text-Box">
          <h1 style={{fontWeight:"lighter"}}>Merhaba</h1>
          <p style={{fontWeight:"lighter", fontSize:"12px"}}>Hadi hemen hesabına giriş yap.</p><br />
          <p style={{fontWeight:"lighter", fontSize:"12px"}}>Bilgini insanlara ulaştırmak için güzel bir yola çıkalım!</p>
        </div>
      </div>
    </div>
    )
    
  )
}

export default MentorLoginComponent