import React, {useContext, useState, useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import '../../Style/HomePage.css'
import BannerImg from '../../Images/HomePage/Group.png'
import { Login } from '@mui/icons-material';

import { LoginContext } from '../../Context/LoginContext';
import { jwtDecode } from 'jwt-decode';

function BannerComponent() {

    const {isLoggedIn, accessToken} = useContext(LoginContext);
    const [isMentorConfirmed, setIsMentorConfirmed] = useState(true);
    const navigate = useNavigate();

    const handleNavigateDashBoard = () => {
        if(accessToken !== null){
            const decodeToken = jwtDecode(accessToken);
            const role = decodeToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
            const isConfirmed = decodeToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication"];
            if(isConfirmed === "False"){
                setIsMentorConfirmed(false);
            }
            const navBtn = document.getElementById("Homepage-DashboardNav-Btn");
            if(navBtn !== null){
                navBtn.addEventListener("click",() => {
                    if(role === "Mentor"){
                        navigate("/mentor-dashboard");
                    }
                    else if(role === "User"){
                        navigate("/mentee-dashboard");
                    }
                })
            }
        }

    }
    
    useEffect(() => {
      handleNavigateDashBoard();
    }, [accessToken])
    
  return (
    <div className='Banner-Component'>
        <div className="Text-Box">
            <div className="Banner-Title">
                <h1>Öğretirken öğren, liderlik et, bağ kur, geliş.</h1>
            </div>
            <div className="Banner-Text">
                <p>
                    Mobimenti, gençlerin kariyer yolculuğunu destekleyen bir mentor ağı oluşturuyor.
                    Mentor olarak daha etkin bir lider, geniş bir ağ ve güçlü bir kariyer inşa etmeye hazır ol!
                </p>
            </div>
            {isLoggedIn && isMentorConfirmed ? (
            <div className="Banner-Buttons" style={{width:"100%", gridTemplateColumns:"2fr 1fr"}}>
                <div className="Banner-Button-2">
                    <button 
                    onClick={handleNavigateDashBoard} 
                    id='Homepage-DashboardNav-Btn'
                    style={{display:"flex", alignItems:"center", justifyContent:"center", gap:"5px"}}
                    >Dahsboard'a git <Login /> </button>
                </div>
            </div>
            ):(
            <div className="Banner-Buttons">
                <div className="Banner-Button-1">
                    <Link to='/register'><button>Kaydol</button></Link>
                </div>
                <div className="Banner-Button-2">
                    <Link to='/login'><button>Giriş Yap</button></Link>
                </div>
            </div>
            )}
        </div>
        <div className="Image-Box">
            <img src={BannerImg} alt="" />
        </div>
    </div>
  )
}

export default BannerComponent