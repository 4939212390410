import React, { useContext, useEffect, useState, useRef } from 'react'
import { LoginContext } from '../../../Context/LoginContext';
import { useNavigate } from 'react-router-dom';
import {AddAPhoto} from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import Logo from '../../../Images/Logo.png';
import LogoCycle from '../../../Images/LoginCycle.png';
import DashboardNavbar from '../DashboardNavbar';
import { Alert, Button, Container, Grid, TextField, Typography, Box } from '@mui/material';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

function MentorSettings() {

  const [mentorInfo, setMentorInfo] = useState({})
  const [name, setName] = useState("")
  const [surname, setSurname] = useState("")
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [linkedin, setLinkedin] = useState("");
  const [school, setSchool] = useState("");
  const [experience, setExperience] = useState("");
  const [job, setJob] = useState("");

  const [image, setImage] = useState(null)

  const {isLoggedIn, accessToken} = useContext(LoginContext);
  const navigate = useNavigate();

  const fileInputRef = useRef(null);

  const decodeToken = jwtDecode(accessToken);
  const mentorId = decodeToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];

  const getData = async () => {

    const response = await axios.get(`https://server.mobimenti.co:80/api/Mentor/${mentorId}`);
    const data = await response.data
    setMentorInfo(data);
    setName(data.name);
    setSurname(data.surname);
    setEmail(data.email);
    setPhoneNumber(data.phoneNumber);
    setLinkedin(data.linkedinProfile);
    setSchool(data.school);
    setExperience(data.experience);
    setJob(data.job);
  }

  const updateUser = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`https://server.mobimenti.co:80/api/Mentor/${mentorId}`,{
        name:name,
        surname:surname,
        email:email,
        phoneNumber:phoneNumber,
        linkedinProfile: linkedin,
        school:school,
        job:job,
        experience:experience
      });
      const imageResponse = await axios.post(`https://server.mobimenti.co:80/api/Mentor/upload-image/${mentorId}`, {
        file:image
      },{
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 200 && imageResponse.status === 200) {
        toast.success("Güncelleme işlemi başarılı.")
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    setImage(file);

  }

  useEffect(() => {
    getData()

  }, [])

  return (
    <>
      <DashboardNavbar title="Ayarlar" />
      <div className="Profile-Update-Form" style={{display:"flex", flexDirection:"column", margin:"100px 0 0 0",gap:"15px", width:"100%", height:"90vh", alignItems:"center", justifyContent:"center"}}>
      <Typography variant="h4" gutterBottom>
        Aşağıdaki form ile bilgilerinizi güncelleyebilirsiniz.
      </Typography>
      <Box padding={3} sx={{backgroundColor:"white"}} borderRadius={2} >
        <form style={{display:"flex", flexDirection:"column",gap:"15px", width:"450px",}}>
          <Grid>
            <Typography variant='h6'>Fotoğraf</Typography>
            <Box display="flex" flex="column" justifyContent="center" >
              <input 
                type="file"
                onChange={handleFileChange}
                ref={fileInputRef}
                style={{ display: 'none' }}
              />
              <Button variant='outlined' sx={{width:"100px", height:"100px"}} onClick={() => {fileInputRef.current.click()}} > {image ? (
                <img style={{objectFit:"cover", width:"100px", height:"100px"}} className='additional-information-image' src={URL.createObjectURL(image)} alt="" />
              ):(
                <AddAPhoto fontSize='large' />
              )} </Button>
            </Box>
          </Grid>
          <Grid display="flex" gap={2} >
          <TextField
            fullWidth
            required
            variant='standard'
            label="İsim"
            value={name}
            onChange={(e) => {setName(e.target.value)}}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            fullWidth
            required
            variant='standard'
            label="Soyisim"
            value={surname}
            onChange={(e) => {setSurname(e.target.value)}}
            InputLabelProps={{
              shrink: true,
            }}
          />
          </Grid>
          <Grid display="flex" gap={2} >
          <TextField
            fullWidth
            required
            variant='standard'
            label="Email"
            value={email}
            onChange={(e) => {setEmail(e.target.value)}}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            fullWidth
            required
            variant='standard'
            label="Telefon Numarası"
            value={phoneNumber}
            onChange={(e) => {setPhoneNumber(e.target.value)}}
            InputLabelProps={{
              shrink: true,
            }}
          />
          </Grid>
          <TextField
            required
            variant='standard'
            label="LinkedIn Profili"
            value={linkedin}
            onChange={(e) => {setLinkedin(e.target.value)}}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            required
            variant='standard'
            label="Okul/Bölüm"
            value={school}
            onChange={(e) => {setSchool(e.target.value)}}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            required
            variant='standard'
            label="Meslek"
            value={job}
            onChange={(e) => {setJob(e.target.value)}}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            required
            variant='standard'
            label="Tecrübe"
            value={experience}
            onChange={(e) => {setExperience(e.target.value)}}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button variant='contained' onClick={(e) => {updateUser(e)}}>Güncelle</Button>
        </form>
      </Box>
        
      </div>
    
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
  </>
  )
}

export default MentorSettings