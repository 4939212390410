import React, { useContext, useEffect, useState } from 'react'
import { LoginContext } from '../../../Context/LoginContext';
import { useNavigate, Link } from 'react-router-dom';
import {Home, CalendarMonth, Chat, Settings, Close, Check} from '@mui/icons-material';


import Logo from '../../../Images/Logo.png';
import LogoCycle from '../../../Images/LoginCycle.png';
import DashboardNavbar from '../DashboardNavbar';
import { Alert, Button, TextField, Typography } from '@mui/material';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

function Messages() {

  return (
    <>
      <DashboardNavbar title="Mesajlar" />
      <div style={{marginTop:"200px", textAlign:"center"}}>
        <Typography variant="h4" gutterBottom>
          Bu özelliğin üzerinde çalışıyoruz.
        </Typography>
      </div>
    </>
  )
}

export default Messages