import React, {useState, useEffect} from 'react'
import DashboardNavbar from '../Dashboard/DashboardNavbar'
import { Avatar, Button, TextField, Grid, Box, Typography, Container } from '@mui/material'
import { DeleteForever } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';


function AdminSettings() {
    const [admins, setAdmins] = useState([]);
    
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const columns = [
        {
          field: 'id',
          headerName: "ID",
          sortable: true,
          
        },
        {
          field: 'firstName',
          headerName: "İsim",
          sortable: true,
          flex: 1,
        },
        {
          field: 'lastName',
          headerName: "Soyisim",
          sortable: true,
          flex: 1,
        },
        {
          field: 'email',
          headerName: "Email",
          flex: 1,
        },  
        {
          field: '',
          headerName: "",
          sortable: false,
          flex: 1,
          renderCell: (params) => (  
              <>
              <Button variant='contained' color='error' style={{marginLeft:"10px"}} onClick={() => handleDelete(params.row.id)} ><DeleteForever style={{color:"white"}} /></Button>
              </>
            
          ),
        },
      ]

    const handleSubmit = async(e) => {
        e.preventDefault();
        try {
            const response = await axios.post("https://server.mobimenti.co:80/api/AdminAuth", {
                firstName: name,
                lastName: surname,
                email: email,
                password: password
            })
            if(response.status == 200){
                toast.success("Yönetici Eklendi")
                setName("")
                setSurname("")
                setEmail("")
                setPassword("")
            }
        } catch (error) {
            toast.error(error)
        }
    }

    const handleDelete = async (id) => {
        try {
            const response = await axios.delete(`https://server.mobimenti.co:80/api/Admin/${id}`);
            if (response.status == 200) {
                toast.success("Yönetici Silindi")
            }
        } catch (error) {
            toast.error(error)
        }
    }

    const getAdmins = async () => {
        try {
            const response = await axios.get("https://server.mobimenti.co:80/api/Admin");
            if (response.status == 200) {
                setAdmins(response.data);
            } 
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
      getAdmins();
    }, [])
    

  return (
    <>
    <DashboardNavbar title="Ayarlar" />
    <div>
    <Container component="main" maxWidth="xl" style={{marginTop:"100px"}}>
        
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            
          }}
        >
          <Typography textAlign="start" component="h1" variant="h5">
            Yeni yönetici ekle
          </Typography>
          <Box component="form" noValidate onSubmit={(e) => {handleSubmit(e)}} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  onChange={(e) => {setName(e.target.value)}}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  onChange={(e) => {setSurname(e.target.value)}}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={(e) =>{setEmail(e.target.value)}}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={(e) => {setPassword(e.target.value)}}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Yönetici Ekle
            </Button>
          </Box>
        </Box>
        <Box >
        {admins && (
            <DataGrid
            rows={admins} 
            columns={columns} 
            style={{backgroundColor:"white", width:"100%"}} 
            checkboxSelection 
            initialState={{
            pagination: {
                paginationModel: {
                pageSize: 15,
                },
            },
            }}
            pageSizeOptions={[15]}
            disableRowSelectionOnClick
            />
        )}
        </Box>
      </Container>
    </div>
    <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
    />
    </>
  )
}

export default AdminSettings