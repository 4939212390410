import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../Images/Logo.png'
import '../Style/Footer.css'

function Footer() {
  return (
    <div className='Footer' 
    style={{width:"100%", height:"100px", display:"flex", alignItems:"center", justifyContent:"space-around", borderTop:"1px solid #EEEDEB", padding:"0 10px"}}>
        <div className="Footer-Left" style={{display:"flex", gap:"20px"}}>
            <div className="Logo">
                <img src={Logo} alt="" />
            </div>
            <div className="Footer-Menu" style={{display:"flex", alignItems:"center"}}>
                <ul  style={{display:"flex", gap:"15px"}}>
                    <li>
                        <Link to="/">Ana Sayfa</Link>
                    </li>
                    <li>
                        <Link to="/about-us" >Biz Kimiz</Link>
                    </li>
                    <li>
                        <Link to='/mentors'>Mentorlarımız</Link>
                    </li>
                    <li>
                        <Link to="https://www.mobimenti.co/MobiBlog/">MobiBlog</Link>
                    </li>
                    <li>
                        <Link to="/contact-us">Bize Ulaş</Link>
                    </li>
                    <li>
                        <Link to="/admin">Admin</Link>
                    </li>
                </ul>
            </div>
        </div>
        <div className="Footer-Right"><span style={{opacity:"0.5"}}>© 2024 Mobimenti, Tüm hakları saklıdır.</span></div>
    </div>
  )
}

export default Footer