import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { Grid } from '@mui/material'
import Mentoring1 from '../Images/OurMentors/Mentoring1.jpg'
import Mentoring2 from '../Images/OurMentors/Mentoring2.jpg'

function AboutUs() {
  return (
    <div>
        <header>
            <Navbar/>
        </header>
        <div>
            <Grid container sx={{border:"1px solid var(--default-font-color-light)", margin:"1rem 0", borderRadius:"8px"}}>
                <Grid container sm={12} padding={2}>
                    <Grid sm={6} xs={12} padding={2}>
                        <h2>Hakkımızda</h2>
                        <p style={{fontSize:"14px"}}>Mobimenti, kariyer yolculuğunda hedeflediğin alanda birebir mentor bulacağın platformdur. <br />
                        Gençlerin kariyer yolculuklarında rehberlik ve bilinçli planlama eksikliğini fark eden bir grup genç girişimci tarafından kurulmuştur. 
                        Dünyada genç bireylerin eğitim ve iş hayatında doğru rehberlik eksikliği nedeniyle yanlış kariyer ve iş seçimleri yapmalarını önlemeyi hedeflemekteyiz. <br /><br />
                        Mobimenti'nin misyonu gençleri mentorluk yöntemiyle kariyer planlamalarında etkili bir şekilde desteklemektir. 
                        Gençlerin potansiyellerini keşfetmelerine ve kariyerlerini şekillendirmelerine yardımcı olarak en iyi versiyonlarına ulaşmalarını sağlarız. 
                        Aynı zamanda, deneyimli yetişkinlerin biriktirdiği bilgi ve deneyimi mentorluk yoluyla paylaşarak bu değerli kaynakları geleceğe aktarmayı teşvik ederiz.<br /><br />
                        Mobimenti'nin vizyonu bilgi birikimi ve deneyimleri paylaşarak birbirini destekleyen ve sürekli gelişen global bir ağ oluşturmaktır.<br /><br />
                        Ana hedef kitlemiz, üniversite eğitimi alan ve iş hayatının henüz başlarında olan genç profesyonellerdir. 
                        İkincil hedef kitlemiz ise deneyimli uzman yetişkinlerdir, ki bunlar mentorluk yapabilecek yetkinliğe sahip kişilerdir.<br /><br />
                        </p>
                    </Grid>
                    <Grid display="flex" alignItems="center" justifyContent="center" sm={6} xs={12} padding={2}>
                        <img width={400} height={400} src={Mentoring1} alt="" />
                    </Grid>
                </Grid>
                <Grid container xs={12} padding={2}>
                    <Grid display="flex" alignItems="center" justifyContent="center" sm={6} xs={12} padding={2}>
                        <img width={400} height={400} src={Mentoring2} alt="" />
                    </Grid>
                    <Grid sm={6} xs={12} padding={2}>
                        <h2>Neden Mobimenti?</h2>
                        <p style={{fontSize:"14px"}}>Mobimenti'yi seçmenin birçok nedeni var. İşte bunlardan bazıları:</p><br />
                        <ul style={{fontSize:"14px", listStyle:"circle !important"}}>
                            <li><b>• Gençlerin kariyer ve kişisel gelişimine odaklanıyoruz:</b> Mobimenti, gençlerin kariyerlerini doğru biçimde şekillendirmelerine ve potansiyellerini maksimize etmelerine yardımcı olmak için kurulmuştur.</li>
                            <li><b>• Güvenilir ve nitelikli mentorlar:</b> Platformumuzda, çeşitli sektörlerden deneyimli ve uzman mentorlar bulunmaktadır. Bu mentorlar, gençlerin kariyerleri hakkında değerli rehberlik sunmaktadır.</li>
                            <li><b>• Kişiselleştirilmiş hizmet:</b> Mobimenti, kullanıcılarına kişiselleştirilmiş mentorluk deneyimi sunar. Tercihe yönelik olarak kullanıcının ihtiyaçlarına ve hedeflerine uygun mentorlarla eşleştirilmesini sağlar.</li>
                            <li><b>• Erişilebilirlik ve kolaylık:</b> Platformumuz, gençlerin ve yetişkinlerin kolayca erişebileceği ve kullanabileceği bir arayüze sahiptir. Böylece, kariyer gelişimlerini herhangi bir zamanda ve herhangi bir yerden yönetmelerini sağlar.</li>
                        </ul><br /><br />
                        <p style={{fontSize:"14px"}}>Mobimenti, gençlerin  kariyerlerinde başarılı olmalarına yardımcı olmak için buradadır. Siz de bizimle birlikte kariyer yolculuğunu geliştirmek için hemen Mobimenti'ye katılın!</p>
                    </Grid>
                    
                </Grid>
            </Grid>
        </div>
        <footer>
            <Footer/>
        </footer>
    </div>
  )
}

export default AboutUs