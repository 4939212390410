import React,{useContext, useState} from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { TextField, Input, InputLabel, FormControl, InputAdornment, IconButton, CircularProgress, Alert } from '@mui/material'
import { Label, Visibility, VisibilityOff} from '@mui/icons-material';
import GoogleLogo from '../../Images/AuthPage/Logo-1.png'
import AppleLogo from '../../Images/AuthPage/Logo.png'
import FacebookLogo from '../../Images/AuthPage/Logo-2.png'

import axios from 'axios';
import { LoginContext } from '../../Context/LoginContext';
import { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

import '../../Style/AuthPage.css'

function MenteeLoginComponent() {

  const [showPassword, setShowPassword] = useState(false);

  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState(false);

  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(null)

  const {isLoggedIn, login} = useContext(LoginContext);
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const emailValidation = (e) => {
    e.preventDefault()
    const regex = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z]{2,8})?/g;
    const emailBox = document.getElementById("mentee-sign-email");
  
    if (!regex.test(email)) {

      emailBox.setAttribute("helperText", "Lütfen geçerli bir e-posta giriniz!");

      setEmailError(true);
    } else {

      emailBox.setAttribute("helperText", "");
    }
  };
  
  const handleLogin = async () => {
    try {
      
      setLoading(true);
      const response = await axios.post('https://server.mobimenti.co:80/api/UserAuth/login', {
        email: email,
        password: password
      });
      
      if(response.status === 200){
        const accessToken = response.data;
        login(accessToken);

        //Access Token'ı çöz
        const decodedToken = jwtDecode(accessToken);
        const expiration = decodedToken.exp;
        const userId = decodedToken.nameidentifier;
        const currentTime = Math.floor(Date.now() / 1000);

        if(expiration < currentTime){
          await refreshAccessToken(userId);
        }

        navigate('/mentee-dashboard');
      }

    } catch (error) {
      
      setLoginError(error.response.data);
      
    } finally{
      setLoading(false)
    }
  }

  const refreshAccessToken = (id) => {
    const response = axios.post(`https://server.mobimenti.co:80/api/RefreshToken/refresh-token/${id}`);

    if(response.status === 200){
      login(response.data);
    }
  }

  return (
    loading ? (
      <CircularProgress />
    ):(
      <div className='Login-Component'>
      <div className="Sign-Form">
        <div className="Form">
          <h1 id='Mentee-Form-Header' style={{color:"#7978FF",fontWeight:"400"}}>Giriş Yap</h1>
          <div className="Sign-Options">
            <img src={GoogleLogo} className='Sign-Options-Icon' alt="" style={{width:"20px", margin:'0 10px'}} />
            <img src={AppleLogo} className='Sign-Options-Icon' alt="" style={{width:"20px", margin:'0 10px'}} />
            <img src={FacebookLogo} className='Sign-Options-Icon' alt="" style={{width:"15px", margin:'0 10px'}} />
          </div>
          <div className="Form-Body">
            <form>
            <TextField
              id="mentee-sign-email"
              label="E-Posta Adresi"
              variant="standard"
              autoComplete='email'
              sx={{ m: 1}}
              onChange={(e) => {setEmail(e.target.value)}}
              error={emailError}
              helperText={emailError ? "Lütfen geçerli bir e-posta giriniz!" : ""}
            />
            <FormControl sx={{ m: 1}} variant="standard">
              <InputLabel htmlFor="mentee-standard-adornment-password">Password</InputLabel>
              <Input
                id="mentee-standard-adornment-password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                onChange={(e) => {setPassword(e.target.value)}}
              />
            </FormControl>
            {loginError && (
              <Alert severity='error'>{loginError}</Alert>
            )}
            </form>
            <div className="Form-Bottom">
            <button type="submit" id='Mentee-Sign-Form-Button' 
            onClick={(e) => {
              emailValidation(e);
              handleLogin();
              }}>Giriş Yap</button>
            <div className="Login-Line">
                <div className="first-line"></div>
                <p>Ya da</p>
                <div className="second-line"></div>
              </div>
              <div className="Mobile-Sign-Options">
                <img src={GoogleLogo} className='Sign-Options-Icon' alt="" style={{margin:'0 10px'}} />
                <img src={AppleLogo} className='Sign-Options-Icon' alt="" style={{margin:'0 10px'}} />
                <img src={FacebookLogo} className='Sign-Options-Icon' alt="" style={{margin:'0 10px'}} />
              </div>
            <p style={{fontSize:"12px"}}>Henüz hesabın yok mu? &nbsp;<Link to="/register"><strong>Kaydol</strong></Link></p>
            </div>
          </div>
        </div>
        <div className="Sign-Text-Box">
          <h1 style={{fontWeight:"lighter"}}>Merhaba</h1>
          <p style={{fontWeight:"lighter", fontSize:"12px"}}>Hadi hemen hesabına giriş yap.</p><br />
          <p style={{fontWeight:"lighter", fontSize:"12px"}}>Randevunu alarak mentorunla görüşmeye başla!</p>
        </div>
      </div>
    </div>
    )
    
  )
}

export default MenteeLoginComponent