import React,{useState, useEffect, useContext} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import MenteeLoginComponent from '../Components/AuthPage/MenteeLoginComponent'
import MentorLoginComponent from '../Components/AuthPage/MentorLoginComponent'
import Navbar from '../Components/Navbar'

import axios from 'axios';
import '../Style/AuthPage.css'
import { LoginContext } from '../Context/LoginContext'
import { jwtDecode } from 'jwt-decode'
import Footer from '../Components/Footer'

function AuthPage() {
  const [showSelectionComponent, setShowSelectionComponent] = useState(true)
  const [showMenteeLogin, setShowMenteeLogin] = useState(false)
  const [showMentorLogin, setShowMentorLogin] = useState(false)

  const {isLoggedIn, accessToken, login} = useContext(LoginContext);

  const navigate = useNavigate();

  const handleMenteeComp = () => {
    setShowSelectionComponent(false)
    setShowMenteeLogin(true)
  }

  const handleMentorComp = () => {
    setShowSelectionComponent(false)
    setShowMentorLogin(true)
  }

  const refreshAccessToken = async (id) => {
    // const response = await axios.post(`https://server.mobimenti.co:80/api/RefreshToken/refresh-token/${id}`);
    // console.log(response);

    // if(response.status === 200){
    //   login(response.data);
    // }
  }

  useEffect(() => {
    if(accessToken !== null){
      const decodedToken = jwtDecode(accessToken);
      const expiration = decodedToken.exp;
      const userId = decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];
      const currentTime = Math.floor(Date.now() / 1000);

      if(expiration < currentTime){
        refreshAccessToken(userId);
      }

    }
  },[])

  return (
    <>
      <Navbar />
      <div className='Authentication-Page'>
        
        {showSelectionComponent && (
        <div className="Selection-Component" id='Auth-Selection-Component'>
          <div className="Selection-Container">
            <div className="Mentor-Login">
              <button id='Mentor-Login-Button' onClick={handleMentorComp}>Mentor Girişi</button>
            </div>
            <div className="Line"></div>
            <div className="Mentee-Login">
              <button id='Mentee-Login-Button' onClick={handleMenteeComp}>Menti Girişi</button>
            </div>
          </div>
          <div className="Go-to-register">
            <p>Henüz hesabın yok mu? &nbsp;<Link to="/register"><strong>Kaydol</strong></Link></p>
          </div>
        </div>
        )}
        {showMenteeLogin && (
        <MenteeLoginComponent id="Mentee-Login-Component"/>
        )}
        {showMentorLogin && (
          <MentorLoginComponent />
        )}
      </div>
      <Footer/>
    </>
  )
}

export default AuthPage