import React, { useContext, useEffect, useState } from 'react'
import { LoginContext } from '../../../Context/LoginContext';
import {Check} from '@mui/icons-material';


import DashboardNavbar from '../DashboardNavbar';
import { Alert, Button, TextField, Typography } from '@mui/material';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

function ProfileSettings() {
  const [userInfo, setUserInfo] = useState({})
  const [name, setName] = useState(userInfo.name)
  const [surname, setSurname] = useState(userInfo.surname)
  const [email, setEmail] = useState(userInfo.email)

  const {isLoggedIn, accessToken} = useContext(LoginContext);

  const decodeToken = jwtDecode(accessToken);
  const userId = decodeToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];

  const getData = async () => {

    const response = await axios.get(`https://server.mobimenti.co:80/api/User/${userId}`);
    const data = await response.data
    setUserInfo(data);;
  }

  const updateUser = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`https://server.mobimenti.co:80/api/User/${userId}`,{
        name:name,
        surname:surname,
        email:email
      });
      if (response.status === 200) {
        return(<Alert icon={<Check/>} severity='success'>
          {response.message}
        </Alert>)
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getData()
  }, [])
  

  return (
    <>
      <DashboardNavbar title="Ayarlar" />
        <div className="Profile-Update-Form" style={{display:"flex", flexDirection:"column", margin:"auto 0",gap:"15px", width:"100%", height:"90vh", alignItems:"center", justifyContent:"center"}}>
        <Typography variant="h4" gutterBottom>
          Aşağıdaki form ile bilgilerinizi güncelleyebilirsiniz.
        </Typography>
        {userInfo.name && userInfo.surname && userInfo.email && (
          <form style={{display:"flex", flexDirection:"column",gap:"15px", width:"350px",}}>
            <TextField
              required
              id="outlined-required"
              label="İsim"
              defaultValue={userInfo.name}
              onChange={(e) => {setName(e.target.value)}}
            />
            <TextField
              required
              label="Soyisim"
              defaultValue={userInfo.surname}
              onChange={(e) => {setSurname(e.target.value)}}
            />
            <TextField
              required
              label="Email"
              defaultValue={userInfo.email}
              onChange={(e) => {setEmail(e.target.value)}}
            />
            <Button variant='contained' onClick={(e) => {updateUser(e)}}>Güncelle</Button>
          </form>
        )}
          
        </div>
    </>

      
  )
}

export default ProfileSettings