import React, { useContext, useEffect, useState } from 'react'
import { LoginContext } from '../Context/LoginContext'
import { useNavigate, Link } from 'react-router-dom';
import {Home, CalendarMonth, Chat, Settings, Close} from '@mui/icons-material';

import Logo from '../Images/Logo.png';
import LogoCycle from '../Images/LoginCycle.png';
import DashboardNavbar from '../Components/Dashboard/DashboardNavbar';
import DashboardHeadline from '../Components/Dashboard/DashboardHeadline';
import DashboardInformationComponent from '../Components/Dashboard/DashboardInformationComponent';
import Calendar from '../Components/Dashboard/Calendar/Calendar';
import Messages from '../Components/Dashboard/Messages/Messages';
import UserSettings from '../Components/Dashboard/Settings/UserSettings';



function MenteeDashboard() {
  const [page, setPage] = useState(0)
  const {isLoggedIn} = useContext(LoginContext);
  const navigate = useNavigate();

  const handleCloseSidebar = (e) => {
    e.preventDefault();
    const sidebar = document.querySelector(".Dashboard-Sidebar-Container");
    if (sidebar != null) {
        sidebar.classList.remove("open");
    }
  }

  const getPageContent = (page) => {
    switch (page) {
      case 0:
        return <MainPage/>;
      case 1:
        return <Calendar/>;
      case 2:
        return <Messages/>;
      case 3:
        return <UserSettings/>;
      default:
        return <MainPage/>;
    }
  };

  const MainPage = () => (
    <>
      <DashboardNavbar title="Genel Bakış" />
      <DashboardHeadline userType="User" />
      <DashboardInformationComponent />
    </>
  )

  useEffect(() => {
    if(!isLoggedIn){
      navigate('/login');
    }
  },[])

  return (
    <div className='Dashboard'>
      <div className="Dashboard-Sidebar-Container">
        <div className="Dashboard-Sidebar">
        <div onClick={(e) => {handleCloseSidebar(e)}} className="Sidebar-Menu-Close-Btn" >
              <Close/>
            </div>
          <div className="Dashboard-Logo" style={{cursor:"pointer"}} >

            <div className="Logo" onClick={() => {navigate('/')}}>
              <img src={Logo} alt="" />
            </div>
            <div className="Dashboard-Line"></div>
          </div>
          <div className="Dashboard-Menu-Container">

            <div className="Dashboard-Menu">
              <Link onClick={() => {setPage(0)}} className="Dashboard-Menu-Item">
                <div className="Dashboard-Menu-Item-Icon"><Home /></div>
                <div className="Dashboard-Menu-Item-Text">Genel</div>
              </Link>
              <Link onClick={() => {setPage(1)}} className="Dashboard-Menu-Item">
                <div className="Dashboard-Menu-Item-Icon"><CalendarMonth/></div>
                <div className="Dashboard-Menu-Item-Text">Takvim</div>
              </Link>
              <Link onClick={() => {setPage(2)}} className="Dashboard-Menu-Item">
                <div className="Dashboard-Menu-Item-Icon"><Chat /></div>
                <div className="Dashboard-Menu-Item-Text">Mesajlar</div>
              </Link>
              <Link onClick={() => {setPage(3)}} className="Dashboard-Menu-Item">
                <div className="Dashboard-Menu-Item-Icon"><Settings /></div>
                <div className="Dashboard-Menu-Item-Text">Ayarlar</div>
              </Link>   
            </div>
          </div>
          {/* <div className="Upgrade-Container">
            <div className="Upgrade-Container-Cycle">
              <img className="Upgrade-Container-Cycle-Img" src={LogoCycle} alt="" />
            </div>
            <p className="Upgrade-Container-Title">PRO'ya Yükselt</p>
            <p className="Upgrade-Container-Text">Mobimenti PRO ile gelişim sürecinizi iyileştirin 
              ve daha fazlasını yapmaya başlayın!</p>
            <button className="Upgrade-Container-Button">PRO'ya Yükselt</button>
          </div> */}
        </div>
      </div>
      <div className="General-Dashboard-Screen">
        <div className="General-Dashboard-Screen-Container">
          {getPageContent(page)}
        </div>
      </div>

    </div>
  )
}

export default MenteeDashboard