import React, { useContext, useEffect, useState } from 'react'
import { LoginContext } from '../Context/LoginContext'
import { useNavigate, Link } from 'react-router-dom';
import {Home, CalendarMonth, Chat, Settings, Close, ErrorOutline} from '@mui/icons-material';
import { Button, Grid, Modal, Typography } from '@mui/material';

import '../Style/Dashboard.css';
import Logo from '../Images/Logo.png';
import LogoCycle from '../Images/LoginCycle.png';
import DashboardNavbar from '../Components/Dashboard/DashboardNavbar';
import DashboardHeadline from '../Components/Dashboard/DashboardHeadline';
import DashboardInformationComponent from '../Components/Dashboard/DashboardInformationComponent';
import MentorCalendar from '../Components/Dashboard/Calendar/MentorCalendar';
import MentorMessages from '../Components/Dashboard/Messages/MentorMesages';
import MentorSettings from '../Components/Dashboard/Settings/MentorSettings';

function MentorDashboard() {
  const {isLoggedIn, accessToken} = useContext(LoginContext);
  const navigate = useNavigate();

  const [complete, setComplete] = useState(true)
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [page, setPage] = useState(0)

  // const checkComplete = async () => {
  //   const decodedToken = jwtDecode(accessToken);
  //   const userId = decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];
  //   const isConfirmed = decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication"];

  //   const information = await axios.get(`https://server.mobimenti.co:80/api/Mentor/${userId}`);
  //   const informationData = information.data;
    
  //   for (const item of Object.values(informationData)) {
      
  //     if (item === "" || item === null) {
  //       await setComplete(false);
        
  //       navigate('/additional-information')
  //       break; // Döngüyü durdur
  //     }
  //   }

  //   if(isConfirmed === "False"){
  //     setConfirmationModal(true);
  //   }
    
  // }

  const handleCloseSidebar = (e) => {
    e.preventDefault();
    const sidebar = document.querySelector(".Dashboard-Sidebar-Container");
    if (sidebar != null) {
        sidebar.classList.remove("open");
    }
    
  }

  const getPageContent = (page) => {
    switch (page) {
      case 0:
        return <MainPage/>;
      case 1:
        return <MentorCalendar/>;
      case 2:
        return <MentorMessages/>;
      case 3:
        return <MentorSettings/>;
      default:
        return <MainPage/>;
    }
  };

  const MainPage = () => (
    <>
      <DashboardNavbar title="Genel Bakış" />
      <Grid display="flex" alignItems="center" marginTop="120px" marginBottom="-100px" gap={1}>
        <ErrorOutline sx={{color:"red"}} fontSize='small' />
        <p color='red'>Sizi değerlendirmeye alabilmemiz için eksik bilgileriniz varsa "Ayarlar" bölümünden tamamlamanız gerekmektedir.</p>
      </Grid>
      <DashboardHeadline userType="Mentor" />
      <DashboardInformationComponent />
    </>
  )

  useEffect(() => {
    if(!isLoggedIn){
      navigate('/login');
    }
    
  },[])
  return (
    <div className='Dashboard'>
      <Modal open={confirmationModal}>
        <div className="Modal-Content" style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"20px"}}>
          <Typography variant='h6'>Başvurunuz ekibimize ulaştı. Değerlendirip en kısa sürede dönüş yapacağız.</Typography>
          <Link to='/'><Button variant='contained'>Ana Sayfaya Dön</Button></Link>
        </div>
      </Modal>
      <div className="Dashboard-Sidebar-Container">
      <div className="Dashboard-Sidebar">
      <div onClick={(e) => {handleCloseSidebar(e)}} className="Sidebar-Menu-Close-Btn" >
            <Close/>
          </div>
        <div className="Dashboard-Logo" style={{cursor:"pointer"}} >

          <div className="Logo" onClick={() => {navigate('/')}}>
            <img src={Logo} alt="" />
          </div>
          <div className="Dashboard-Line"></div>
        </div>
        <div className="Dashboard-Menu-Container">

          <div className="Dashboard-Menu">
            <Link onClick={() => {setPage(0)}} className="Dashboard-Menu-Item">
              <div className="Dashboard-Menu-Item-Icon"><Home /></div>
              <div className="Dashboard-Menu-Item-Text">Genel</div>
            </Link>
            <Link onClick={() => {setPage(1)}} className="Dashboard-Menu-Item">
              <div className="Dashboard-Menu-Item-Icon"><CalendarMonth/></div>
              <div className="Dashboard-Menu-Item-Text">Takvim</div>
            </Link>
            <Link onClick={() => {setPage(2)}} className="Dashboard-Menu-Item">
              <div className="Dashboard-Menu-Item-Icon"><Chat /></div>
              <div className="Dashboard-Menu-Item-Text">Mesajlar</div>
            </Link>
            <Link onClick={() => {setPage(3)}} className="Dashboard-Menu-Item">
              <div className="Dashboard-Menu-Item-Icon"><Settings /></div>
              <div className="Dashboard-Menu-Item-Text">Ayarlar</div>
            </Link>   
          </div>
        </div>
        {/* <div className="Upgrade-Container">
          <div className="Upgrade-Container-Cycle">
            <img className="Upgrade-Container-Cycle-Img" src={LogoCycle} alt="" />
          </div>
          <p className="Upgrade-Container-Title">PRO'ya Yükselt</p>
          <p className="Upgrade-Container-Text">Mobimenti PRO ile gelişim sürecinizi iyileştirin 
            ve daha fazlasını yapmaya başlayın!</p>
          <button className="Upgrade-Container-Button">PRO'ya Yükselt</button>
        </div> */}
      </div>
    </div>
      <div className="General-Dashboard-Screen">
        <div className="General-Dashboard-Screen-Container">
          {getPageContent(page)}
        </div>
      </div>
    </div>
  )
}

export default MentorDashboard