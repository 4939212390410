import React from 'react'
import DashboardMeetingsComponent from './DashboardMeetingsComponent'
import DashboardCalendarComponent from './DashboardCalendarComponent'
import DashboardMessagesComponent from './DashboardMessagesComponent'

function DashboardInformationComponent() {
  return (
    <div className='Dashboard-Information-Component'>
        <div className="Meetings-Container">
            <DashboardMeetingsComponent />
        </div>
        <div className="Calendar-Container">
            <DashboardCalendarComponent />
        </div>
        <div className="Messages-Container">
            <DashboardMessagesComponent />
        </div>
    </div>
  )
}

export default DashboardInformationComponent