import React, {useState, useContext, useEffect} from 'react'
import { useNavigate, Link } from 'react-router-dom';
import {Home, CalendarMonth, Settings, Person, HowToReg, Close} from '@mui/icons-material';

import Logo from '../../Images/Logo.png';
import LogoCycle from '../../Images/LoginCycle.png';
import DashboardNavbar from '../Dashboard/DashboardNavbar';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';

function formatReadableDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric'};
    const formattedDate = new Intl.DateTimeFormat('tr-TR', options).format(new Date(dateString));
    return formattedDate;
  }

function DashboardUserList({userCount, setUserCount}) {
    const [users, setUsers] = useState([])
    const [selectedRows, setSelectedRows] = useState([])

    const navigate = useNavigate();

    const columns = [
        {
          field: 'id',
          headerName: "ID",
          sortable: true,
          
        },
        {
          field: 'name',
          headerName: "İsim",
          sortable: true,
          
        },
        {
          field: 'surname',
          headerName: "Soyisim",
          sortable: true,
          
        },
        {
          field: 'email',
          headerName: "Email",
          flex: 1,
          maxWidth:300
        },
        {
          field: 'created',
          headerName: "Kayıt Tarihi",
          sortable: true,
          flex: 1,
          renderCell: (params) => (
            <span>{formatReadableDate(params.value)}</span>
          )
        },
        {
          field: '',
          headerName: "İşlemler",
          renderCell: (params) => (
            <Button variant='contained' color='error' onClick={() => {handleDelete(params.row.id)}} ><Close style={{color:"white"}} /></Button>
          ),
        },
  
      ]

    const getUsers = async() => {
        try {
            const response = await axios.get("https://server.mobimenti.co:80/api/User");
            const data = response.data;

            setUsers(data);
            setUserCount(data.length)
        } catch (error) {
            console.log(error);
        }
    }

    const handleDelete = async (id) => {
      try {
        const response = await axios.delete(`https://server.mobimenti.co:80/api/User/${id}`);
        if(response.status === 200){
          toast.success("İşlem Başarılı");
          getUsers();
        }

      } catch (error) {
        console.log(error);
      }
    };

    useEffect(() => {
      getUsers();
    }, [])
    
  
  return (
        <>
          <DashboardNavbar title="Kullanıcılar" />
          <div className="Dashboard-UserList-Container" style={{marginTop:"200px"}}>
              {users && (
                  <DataGrid 
                  rows={users} 
                  columns={columns} 
                  style={{backgroundColor:"white", width:"100%"}} 
                  checkboxSelection 
                  initialState={{
                  pagination: {
                      paginationModel: {
                      pageSize: 15,
                      },
                  },
                  }}
                  pageSizeOptions={[15]}
                  disableRowSelectionOnClick
                  />
              )}
              
          </div>
        </>
  )
}

export default DashboardUserList