import React from 'react';
import CampusProject from '../../Images/HomePage/CampusProject.png';
import MentorignProgram from '../../Images/HomePage/MentoringProgram.png';

import '../../Style/HomePage.css'

function ProjectCards() {
  return (
    <div className='Project-Cards'>
        <div className="Project-Card-1">
            <div className="Project-Icon">
                <img src={CampusProject} alt="" />
            </div>
            <div className="Project-Title">
                <h3>Mobimenti Kampüs Lideri</h3>
            </div>
        </div>
        <div className="Project-Card-2">
            <div className="Project-Icon">
                <img src={MentorignProgram} alt="" />
            </div>
            <div className="Project-Title">
                <h3>Mobimenti Mentorluk Programı</h3>
            </div>
        </div>
    </div>
  )
}

export default ProjectCards