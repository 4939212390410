import React, { useContext, useEffect, useState } from 'react';
import { LoginContext } from '../Context/LoginContext';
import { useNavigate, Link } from 'react-router-dom';
import {Home, CalendarMonth, Chat, Settings, Person, HowToReg} from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../Style/Dashboard.css';
import '../Style/AdminDashboard.css';
import Logo from '../Images/Logo.png';
import LogoCycle from '../Images/LoginCycle.png';
import DashboardNavbar from '../Components/Dashboard/DashboardNavbar';
import DashboardHeadline from '../Components/Dashboard/DashboardHeadline';
import DashboardInformationComponent from '../Components/Dashboard/DashboardInformationComponent';
import AdminScheduler from '../Components/Dashboard/Calendar/AdminCalendar';
import DashboardUserList from '../Components/Admin/DashboardUserList';
import DashboardMentorList from '../Components/Admin/DashboardMentorList';
import UserList from '../Components/Admin/UserList';
import AdminSettings from '../Components/Admin/AdminSettings';
import { 
  FormControl, 
  Select,
  Grid,
  Typography,
  InputLabel,
  MenuItem,
  TextField,
  Button,
  Switch
 } from '@mui/material';
import axios from 'axios';
import MeetingRequests from '../Components/Admin/MeetingRequests';
import AdminLogin from '../Components/Admin/AdminLogin';
import { jwtDecode } from 'jwt-decode';

function AdminDashboard() {

  const [isAdmin, setIsAdmin] = useState(false);
  const [mentors, setMentors] = useState([]);
  const [users, setUsers] = useState([])

  const [selectedMentor, setSelectedMentor] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedDateTime, setSelectedDateTime] = useState('');

  const [userCount, setUserCount] = useState(0);
  const [totalMeeting, setTotalMeeting] = useState(0);
  const [page, setPage] = useState(0);

  const getOptions = async () => {
    try {
      const userResponse = await axios.get("https://server.mobimenti.co:80/api/User");
      const userData = userResponse.data;
      setUsers(userData);
      
      const mentorResponse = await axios.get("https://server.mobimenti.co:80/api/Mentor");
      const mentorData = mentorResponse.data;
      setMentors(mentorData);

    } catch (error) {
      console.log(error);
    }
  }

  const handleMentorChange = (event) => {
    event.preventDefault();
    setSelectedMentor(event.target.value);

    const createContainer = document.getElementById("Create-Meeting-Container");
    if (createContainer !== null) {
      createContainer.style.display = "flex";
    }
  };

  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  const handleDateTimeChange = (event) => {
    setSelectedDateTime(event.target.value);
  };

  const closeCreateContainer = () => {
    const createContainer = document.getElementById("Create-Meeting-Container");
    if(createContainer !== null){
      createContainer.style.display = "none";
    }
  }

  const handleSubmit = async () => {
    try {
      const response = await axios.post("https://server.mobimenti.co:88/api/Meeting",{
        meetingDate:selectedDateTime,
        userId:selectedUser,
        mentorId:selectedMentor
      },{
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if(response.status === 200){
        toast.success("Toplantı Başarıyla Oluşturuldu.")
      }
    } catch (error) {
      console.log(error);
    }

  };

  const navigate = useNavigate();

  const getPageContent = (page) => {
    switch (page) {
      case 0:
        return <MainPage/>;
      case 1:
        return <AdminScheduler totalMeeting={totalMeeting} setTotalMeeting={setTotalMeeting} />;
      case 2:
        return <DashboardUserList userCount={userCount} setUserCount={setUserCount} />;
      case 3:
        return <DashboardMentorList/>;
      case 4:
        return <AdminSettings/>
      default:
        return <MainPage/>;
    }
  };

  const MainPage = () => (
    <>
      <DashboardNavbar title="Genel Bakış" />
      <DashboardHeadline
        userType="Admin"
        userCount={userCount}
        totalMeeting={totalMeeting}
      />
      <div id="Create-Meeting-Container" className="Create-Meeting-Container">
        <Grid item xs={12}>
          <Typography variant="h4">Toplantı Oluştur</Typography>
        </Grid>
        <Grid
          style={{
            backgroundColor: "var(--dashboard-sidebar-white)",
            padding: "1rem",
            borderRadius: "8px",
          }}
          container
          spacing={2}
        >
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel>Mentor Seçimi</InputLabel>
              <Select
                placeholder="Mentor Seçimi"
                value={selectedMentor}
                onChange={handleMentorChange}
              >
                {mentors
                  .filter((mentor) => mentor.confirmed === true)
                  .map((mentor) => (
                    <MenuItem key={mentor.id} value={mentor.id}>
                      {mentor.name + " " + mentor.surname}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel>Kullanıcı Seçimi</InputLabel>
              <Select value={selectedUser} onChange={handleUserChange}>
                {users.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.name + " " + user.surname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              type="datetime-local"
              value={selectedDateTime}
              onChange={handleDateTimeChange}
            />
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" color="success" onClick={handleSubmit}>
              Oluştur
            </Button>
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="right">
            <Button
              variant="outlined"
              color="primary"
              onClick={closeCreateContainer}
            >
              Kapat
            </Button>
          </Grid>
        </Grid>
      </div>
      <UserList />
      <MeetingRequests />
    </>
  );

  useEffect(() => {
      getOptions();
    
    if(localStorage.getItem("accessToken")){
      const decodeToken = jwtDecode(localStorage.getItem("accessToken"));
      const role = decodeToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]
      if (role === "Admin") {
        setIsAdmin(true);
      }
      
    }
  }, [])
  
  return (
    isAdmin ? (
      <div className='Dashboard'>
          
      <div className="Dashboard-Sidebar-Container">
        <div className="Dashboard-Sidebar">
          <div className="Dashboard-Logo" style={{cursor:"pointer"}} onClick={() => {navigate('/')}}>
            <div className="Logo">
              <img src={Logo} alt="" />
            </div>
            <div className="Dashboard-Line"></div>
          </div>
          <div className="Dashboard-Menu-Container">
            <div className="Dashboard-Menu">
              <Link onClick={() => {setPage(0)}} className="Dashboard-Menu-Item">
                <div className="Dashboard-Menu-Item-Icon"><Home /></div>
                <div className="Dashboard-Menu-Item-Text">Genel</div>
              </Link>
              <Link onClick={() => {setPage(1)}} className="Dashboard-Menu-Item">
                <div className="Dashboard-Menu-Item-Icon"><CalendarMonth/></div>
                <div className="Dashboard-Menu-Item-Text">Görüşmeler</div>
              </Link>
              <Link onClick={() => {setPage(2)}} className="Dashboard-Menu-Item">
                <div className="Dashboard-Menu-Item-Icon"><Person /></div>
                <div className="Dashboard-Menu-Item-Text">Kullanıcılar</div>
              </Link>
              <Link onClick={() => {setPage(3)}} className="Dashboard-Menu-Item">
                <div className="Dashboard-Menu-Item-Icon"><HowToReg /></div>
                <div className="Dashboard-Menu-Item-Text">Mentorlar</div>
              </Link>  
              <Link onClick={() => {setPage(4)}} className="Dashboard-Menu-Item">
                <div className="Dashboard-Menu-Item-Icon"><Settings /></div>
                <div className="Dashboard-Menu-Item-Text">Ayarlar</div>
              </Link>   
            </div>
          </div>
          {/* <div className="Upgrade-Container">
            <div className="Upgrade-Container-Cycle">
              <img className="Upgrade-Container-Cycle-Img" src={LogoCycle} alt="" />
            </div>
            <p className="Upgrade-Container-Title">PRO'ya Yükselt</p>
            <p className="Upgrade-Container-Text">Mobimenti PRO ile gelişim sürecinizi iyileştirin 
              ve daha fazlasını yapmaya başlayın!</p>
            <button className="Upgrade-Container-Button">PRO'ya Yükselt</button>
          </div> */}
        </div>
      </div>
      <div className="General-Dashboard-Screen">
        <div className="General-Dashboard-Screen-Container">
          {getPageContent(page)}
        </div>
      </div>
      <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
    </div>
    ):
    (<AdminLogin isAdmin={isAdmin} setIsAdmin={setIsAdmin} />)
    
  )
}

export default AdminDashboard