import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import RegisterComponent from '../Components/AuthPage/MentorRegisterComponent'
import MentorRegisterComponent from '../Components/AuthPage/MentorRegisterComponent'
import MenteeRegisterComponent from '../Components/AuthPage/MenteeRegisterComponent'
import Navbar from '../Components/Navbar'

import '../Style/Register.css'
import Footer from '../Components/Footer'

function RegisterPage() {

  const [showSelectionComponent, setShowSelectionComponent] = useState(true)
  const [showMenteeRegister, setShowMenteeRegister] = useState(false)
  const [showMentorRegister, setShowMentorRegister] = useState(false)

  const handleMentorComp = () => {
    setShowSelectionComponent(false)
    setShowMentorRegister(true)
  }

  const handleMenteeComp = () => {
    setShowSelectionComponent(false)
    setShowMenteeRegister(true)
  }

  return (
    <>
    <Navbar />
    <div className='Register-Page'>
        {showSelectionComponent && (
          <>
          <div className="Selection-Component" id='Auth-Selection-Component'>
          <div className="Selection-Container">
            <div className="Mentor-Login">
              <button id='Mentor-Login-Button' onClick={handleMentorComp} style={{width:"100%", fontSize:"20px", padding:"10px 10px", borderRadius:"8px"}} >Mentor Olmak İstiyorum</button>
            </div>
            <div className="Line"></div>
            <div className="Mentee-Login">
              <button id='Mentee-Login-Button' onClick={handleMenteeComp} style={{width:"100%", fontSize:"20px", padding:"10px 10px", borderRadius:"8px"}} >Menti Olmak İstiyorum</button>
            </div>
          </div>
          <div className="Go-to-register">
            <p>Zaten hesabın var mı? &nbsp;<Link to="/login"><strong>Giriş Yap</strong></Link></p>
          </div>
        </div>
          </>
        )}
        {showMentorRegister && (
          <MentorRegisterComponent />
        )}
        {showMenteeRegister && (
          <MenteeRegisterComponent />
        )}
        
    </div>
    <Footer/>
    </>
  )
}

export default RegisterPage