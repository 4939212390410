import React from 'react'
import Discover from '../../Images/HomePage/discover.png'
import Choosing from '../../Images/HomePage/choosing.png'
import Meeting from '../../Images/HomePage/users.png'

function InfoCards() {
  return (
    <div className='Info-Cards'>
        <div className="Info-Box-1">
            <div className="Image-Box">
                <img src={Discover} alt="" />
            </div>
            <div className="Text-Box">
                <div className="Info-Box-Title">
                    <h3>Mentorları Keşfet</h3>
                </div>
                <div className="Info-Box-Text">
                    <p>Kariyer adımlarında ilerlemek
                        istediğin katagorideki 
                        mentorları keşfet!
                    </p>
                </div>
            </div>
        </div>
        <div className="Info-Box-2">
            <div className="Image-Box">
                <img src={Choosing} alt="" />
            </div>
            <div className="Text-Box">
                <div className="Info-Box-Title">
                    <h3>İncele ve Seç</h3>
                </div>
                <div className="Info-Box-Text">
                    <p>Mentorları incele ve
                        mentorunu seç!
                    </p>
                </div>
            </div>
        </div>
        <div className="Info-Box-3">
            <div className="Image-Box">
                <img src={Meeting} alt="" />
            </div>
            <div className="Text-Box">
                <div className="Info-Box-Title">
                    <h3>Randevu Al,
                        Görüşmeye Başla!
                    </h3>
                </div>
                <div className="Info-Box-Text">
                    <p>Mobimenti’den randevunu alarak
                        mentorunla görüşmeye başla!
                    </p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default InfoCards