import React, {useState} from 'react'
import { Box, Avatar, Typography, TextField, Button,Container} from '@mui/material'
import { LockClockOutlined} from '@mui/icons-material'
import axios from 'axios';

function AdminLogin({isAdmin, setIsAdmin}) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = async(e) => {
      e.preventDefault();
      try {
        const response = await axios.post("https://server.mobimenti.co:80/api/AdminAuth/admin-login",{
          email:email,
          password: password
        });
        if (response.status === 200) {
          setIsAdmin(true);
          localStorage.setItem("accessToken", response.data);
        }
      } catch (error) {
        console.log(error);
      }
    }

  return (
    <div>
        <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockClockOutlined />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={(e) => {handleSubmit(e)}} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => {setEmail(e.target.value)}}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => {setPassword(e.target.value)}}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
    </div>
  )
}

export default AdminLogin