import React, { useState, useContext, useEffect, useRef } from 'react'
import { useNavigate, Link } from 'react-router-dom';

import { Home, CalendarMonth, Settings, Person, HowToReg, Close, Check, AddAPhoto, RemoveRedEye } from '@mui/icons-material';
import { Button, Container, Typography, Grid, TextField, Box, Dialog, Modal } from '@mui/material';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReactDataGrid from '@inovua/reactdatagrid-community'


import DashboardNavbar from '../Dashboard/DashboardNavbar';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import axios from 'axios';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Header } from 'postmark';

function formatReadableDate(dateString) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = new Intl.DateTimeFormat('tr-TR', options).format(new Date(dateString));
  return formattedDate;
}

function DashboardMentorList() {
  const [mentors, setMentors] = useState([])
  const [name, setName] = useState("")
  const [surname, setSurname] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [linkedin, setLinkedin] = useState("");
  const [school, setSchool] = useState("");
  const [experience, setExperience] = useState("");
  const [job, setJob] = useState("");
  const [image, setImage] = useState(null);

  const [openModal, setOpenModal] = useState(false)

  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const columns = [
    {
      field: 'id',
      headerName: "ID",
      sortable: true,
      width:30
    },
    {
      field: 'name',
      headerName: "İsim",
      sortable: true,
      width:100
    },
    {
      field: 'surname',
      headerName: "Soyisim",
      sortable: true,
      width:100
    },
    {
      field: 'email',
      headerName: "Email",
      flex: 1,
    },
    {
      field: 'school',
      headerName: "Okul/Bölüm",
      flex: 1,
    },
    {
      field: 'job',
      headerName: "Meslek",
      flex: 1,
    },
    {
      field: 'experience',
      headerName: "Tecrübe",
      sortable: true,
      flex: 1,
    },
    {
      field: 'linkedinProfile',
      headerName: "Linkedin",
      flex: 1,
    },
    {
      field: 'created',
      headerName: "Kayıt Tarihi",
      sortable: true,
      flex: 1,
      renderCell: (params) => (
        <span>{formatReadableDate(params.value)}</span>
      )
    },

    {
      field: 'confirmed',
      headerName: "Durum",
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        params.row.confirmed ? 
        (<span style={{color:"green"}}>Onaylandı</span>) : 
        (
          <>
          <span style={{color:"orange"}}>Bekliyor..</span>
          
          <Button variant='outlined' style={{marginLeft:"10px"}} onClick={() => handleConfirm(params.row.id)} ><Check style={{color:"green"}} /></Button>
          </>
        )
      ),
    },

    {
      field: '',
      headerName: "İşlemler",
      flex:1,
      renderCell: (params) => (
        <>
          <Button sx={{backgroundColor:"transparent", border:"none"}}> <RemoveRedEye/> </Button>
          <Button variant='contained' color='error' onClick={() => {handleDelete(params.row.id)}} ><Close style={{color:"white"}} /></Button>
        </>
      ),
    },
  ]
  const gridStyle = { minHeight: 550 }

  const column = [
    {
      field: 'id',
      headerName: "ID",
      sortable: true,
    },
    {
      field: 'name',
      headerName: "İsim",
      sortable: true,
      flex: 1,
    },
    {
      field: 'surname',
      headerName: "Soyisim",
      sortable: true,
      flex: 1,
    },
    {
      field: 'email',
      headerName: "Email",
      flex: 1,
    },
    {
      field: ' ',
      headerName: "Detaylar",
      renderCell:(params) => {
        if (!params || !params.row) {
          return null;
        }
        return (
          <Button onClick={() => {handleModal(params.row.id)}} > <RemoveRedEye/> </Button>
        );
      }
    },
    {
      field: 'created',
      headerName: "Kayıt Tarihi",
      sortable: true,
      flex: 1,
      renderCell: (params) => (
        <span>{formatReadableDate(params.value)}</span>
      )
    },

    {
      field: 'confirmed',
      headerName: "Durum",
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        params.row.confirmed ?
          (<span style={{ color: "green" }}>Onaylandı</span>) :
          (
            <>
              <span style={{ color: "orange" }}>Bekliyor..</span>
              
            </>
          )
      ),
    },

    {
      field: '',
      headerName: "İşlemler",
      flex:1,
      renderCell: (params) => (
        <>
        <Button variant='outlined' style={{ marginLeft: "10px" }} onClick={() => handleConfirm(params.row.id)} ><Check style={{ color: "green" }} /></Button>
        <Button variant='contained' color='error' onClick={() => { handleDelete(params.row.id) }} ><Close style={{ color: "white" }} /></Button>
        </>
      ),
    },
  ]

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`https://server.mobimenti.co:80/api/Mentor/${id}`);
      if (response.status === 200) {
        getMentors();
      }

    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirm = async (mentorId) => {
    try {
      const response = await axios.put(`https://server.mobimenti.co:80/api/Mentor/${mentorId}`, {
        confirmed: true,
      });
      if (response.status === 200) {
        getMentors();
      }
    } catch (error) {
      console.log(error);
    }

  }

  const getMentors = async () => {
    try {
      const response = await axios.get("https://server.mobimenti.co:80/api/Mentor/Without-Img");
      const data = response.data;

      setMentors(data);

    } catch (error) {
      console.log(error);
    }
  }

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    setImage(file);
  }

  const createMentor = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('surname', surname);
    formData.append('email', email);
    formData.append('passwordHash', password);
    formData.append('phoneNumber', phoneNumber);
    formData.append('confirmed', true);
    formData.append('linkedinProfile', linkedin);
    formData.append('school', school);
    formData.append('job', job);
    formData.append('experience', experience);
    formData.append('imageFile', image);
    try {
      const response = await axios.post(`https://server.mobimenti.co:80/api/MentorAuth/admin-mentor-register`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log(response.data);
      if(response.status === 200){
        toast.success("Ekleme işlemi başarılı.")
      }
    } catch (error) {
      console.error('There was an error!', error);
    }
  }

  const handleModal = (id) => {
    
  }

  useEffect(() => {
    getMentors();
  }, [])


  return (
    <>
      <DashboardNavbar title="Mentorlar" />
      <div
        className="Dashboard-UserList-Container"
        style={{ marginTop: "200px" }}
      >
        {mentors && (
          <DataGrid
            rows={mentors}
            columns={columns}
            style={{ backgroundColor: "white", width: "100%", height: "auto" }}
            autoHeight
            slots={{ toolbar: GridToolbar }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 15,
                },
              },
            }}
            pageSizeOptions={[15]}
            disableRowSelectionOnClick
            columnVisibilityModel={{
              id:false,
              job:false,
              school:false,
              experience:false,
              linkedinProfile:false
            }}
            sx={{zIndex:"0"}}
          />
        )}
      </div>
      <div className="Add-Mentor-Section">
        <Container
          sx={{ marginTop: 5, width: "700px", marginBottom: 5 }}
          maxWidth="xl"
        >
          <h1>Mentor Ekle</h1>
          <div className="Add-Mentor-Form">
            <Box
              padding={3}
              sx={{
                backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              borderRadius={2}
            >
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                  width: "450px",
                }}
              >
                <Grid>
                  <Typography variant="h6">Fotoğraf</Typography>
                  <Box display="flex" flex="column" justifyContent="center">
                    <input
                      type="file"
                      onChange={handleFileChange}
                      ref={fileInputRef}
                      style={{ display: "none" }}
                    />
                    <Button
                      variant="outlined"
                      sx={{ width: "100px", height: "100px" }}
                      onClick={() => {
                        fileInputRef.current.click();
                      }}
                    >
                      {" "}
                      {image ? (
                        <img
                          style={{
                            objectFit: "cover",
                            width: "100px",
                            height: "100px",
                          }}
                          className="additional-information-image"
                          src={URL.createObjectURL(image)}
                          alt=""
                        />
                      ) : (
                        <AddAPhoto fontSize="large" />
                      )}{" "}
                    </Button>
                  </Box>
                </Grid>
                <Grid display="flex" gap={2}>
                  <TextField
                    fullWidth
                    required
                    variant="standard"
                    label="İsim"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    fullWidth
                    required
                    variant="standard"
                    label="Soyisim"
                    value={surname}
                    onChange={(e) => {
                      setSurname(e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid display="flex" gap={2}>
                  <TextField
                    fullWidth
                    required
                    variant="standard"
                    label="Email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    fullWidth
                    required
                    variant="standard"
                    label="Telefon Numarası"
                    value={phoneNumber}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <TextField
                  required
                  variant="standard"
                  label="Şifre"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  required
                  variant="standard"
                  label="LinkedIn Profili"
                  value={linkedin}
                  onChange={(e) => {
                    setLinkedin(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  required
                  variant="standard"
                  label="Okul/Bölüm"
                  value={school}
                  onChange={(e) => {
                    setSchool(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  required
                  variant="standard"
                  label="Meslek"
                  value={job}
                  onChange={(e) => {
                    setJob(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  required
                  variant="standard"
                  label="Tecrübe"
                  value={experience}
                  onChange={(e) => {
                    setExperience(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Button variant="contained" onClick={(e) => createMentor(e)}>
                  Ekle
                </Button>
              </form>
            </Box>
          </div>
        </Container>
      </div>
      <Modal open={openModal}>
        <div>test</div>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default DashboardMentorList