import React, {useContext, useState, useEffect} from 'react'
import '../../Style/Dashboard.css'
import { Notifications, Info, Search, Menu} from '@mui/icons-material'
import { IconButton,TextField } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { LoginContext } from '../../Context/LoginContext'
import { jwtDecode } from 'jwt-decode'
import axios from 'axios'

function DashboardNavbar({title}) {
    const {isLoggedIn, logout, accessToken} = useContext(LoginContext);
    const [isOpenProfileMenu, setIsOpenProfileMenu] = useState(false);
    const [image, setImage] = useState("")
    const [profileName, setProfileName] = useState("")
    const navigate = useNavigate();
    const handleProfileMenu = () => {
        setIsOpenProfileMenu(!isOpenProfileMenu);
    }

    const handleLogout = () => {
        logout();
    };

    const handleSidebarMenu = (e) => {
        e.preventDefault();
        const sidebar = document.querySelector(".Dashboard-Sidebar-Container");
        if (sidebar != null) {
            sidebar.classList.add("open");
        }
    }

    const getName = async () => {
        if(accessToken !== null){
            const decodeToken = jwtDecode(accessToken);
            const userId = decodeToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];
            const userRole = decodeToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
            
            if(userRole === "User"){
                try {
                    const response = await axios.get(`https://server.mobimenti.co:80/api/User/${userId}`);
                    const userName = response.data.name;
                    if(userName !== null){
                        setProfileName(userName);
                    }
                } catch (error) {
                    console.log(error);
                }
            }
            else if(userRole === "Mentor"){
                try {
                    const response = await axios.get(`https://server.mobimenti.co:80/api/Mentor/${userId}`);
                    const userName = response.data.name;
                    if(userName !== null){
                        setProfileName(userName);
                    }
                } catch (error) {
                    console.log(error);
                }
            }
            else if(userRole === "Admin"){
                try {
                    const response = await axios.get(`https://server.mobimenti.co:80/api/Admin/${userId}`);
                    const userName = response.data.firstName;
                    if(userName !== null){
                        setProfileName(userName);
                    }
                } catch (error) {
                    console.log(error);
                }
            }

        }

    }

    useEffect(() => {
        const token = localStorage.getItem("accessToken");
        if (!isLoggedIn && token === null) {
            navigate("/login");
        }
        getName();
    }, [isLoggedIn, navigate]);
    

  return (
    <div className='Dashboard-Navbar' style={{zIndex:"9999"}}>
        <div className="Dashboard-Navbar-Title">
            <p className="Dashboard-Navbar-Hello-Box">
                Merhaba {profileName}, Hoşgeldin!
            </p>
            <h1 className="Dashboard-Navbar-Main-Title">
                {title}
            </h1>
        </div>
        <div className="Dashboard-Navbar-Tools">
            <div className="Dashboard-Navbar-SearchBar">
                <IconButton className='Dashboard-Navbar-Search-Icon'>
                    <Search />
                </IconButton>
                <input type="text" placeholder='Ara..' className='Dashboard-Navbar-Search-Input'/>
            </div>
            <div className="Mobile-Dashboard-Menu-Btn">
                <button onClick={(e) => {handleSidebarMenu(e)}}><Menu className='Mobile-Dashboard-Menu-Icon' /></button>
            </div>
            <div className="Dashboard-Navbar-Notifications">
                <Notifications className='Dashboard-Navbar-Notifications-Icon'/>
            </div>
            <div className="Dashboard-Navbar-Informations">
                <Info className='Dashboard-Navbar-Informations-Icon' />
            </div>
            <div className="Dashboard-Navbar-Profile">
                <button onClick={handleProfileMenu} className="Dashboard-Navbar-Profile-Picture">
                    {image && (
                        <img src={image} alt="" />
                    )}
                    
                </button>
            </div>
            {isOpenProfileMenu && (
                <div className="Dashboard-Navbar-Profile-Menu">
                    <button onClick={handleLogout}>Çıkış Yap</button>
                </div>
            )}

        </div>
    </div>
  )
}

export default DashboardNavbar