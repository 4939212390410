import React, {useState, useContext, useEffect} from 'react'
import { useNavigate, Link } from 'react-router-dom';
import {Home, CalendarMonth, Settings, Person, HowToReg, Close} from '@mui/icons-material';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { LoginContext } from '../../../Context/LoginContext';


import Logo from '../../../Images/Logo.png';
import LogoCycle from '../../../Images/LoginCycle.png';
import DashboardNavbar from '../DashboardNavbar';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';



function AdminScheduler({totalMeeting, setTotalMeeting}) {
  const [events, setEvents] = useState([])

  const navigate = useNavigate();
  const {accessToken, isLoggedIn} = useContext(LoginContext);
  const localizer = momentLocalizer(moment)

  const takeEvents = async() => {

    try {
      const response = await axios.get(`https://server.mobimenti.co:88/api/Meeting`);
      const data = await response.data;
      setTotalMeeting(data.length);
      // Tüm mentorId'leri bir diziye al
      const mentorIds = data.map(item => item.mentorId);
      const userIds = data.map(item => item.userId);

      // Mentor isimlerini saklayacak olan bir nesne
      const mentorNames = {};
      const userNames = {};

      // Her bir mentorId için ayrı bir istek yap ve ismi mentorNames nesnesine ekle
      await Promise.all(mentorIds.map(async mentorId => {
        const mentorResponse = await axios.get(`https://server.mobimenti.co:80/api/Mentor/${mentorId}`);
        const mentorData = mentorResponse.data;
        
        mentorNames[mentorId] = mentorData.name + " " +mentorData.surname;
      }));

      await Promise.all(userIds.map(async userId => {
        const userResponse = await axios.get(`https://server.mobimenti.co:80/api/User/${userId}`);
        const userData = userResponse.data;
        
        userNames[userId] = userData.name + " " +userData.surname;
      }));

      
      if(data.length>0){
        const newEvent = data.map(item => ({
          start: moment(item.meetingDate).toDate(),
          end: moment(item.meetingDate).add(1, 'hour').toDate(),
          title: `Mentor: ${mentorNames[item.mentorId]} ile Kullanıcı: ${userNames[item.userId]} arasında toplantı`
        }));
        setEvents(newEvent);
        
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    takeEvents();
  }, [])
  

  return (
      <>
        <DashboardNavbar title="Takvim" />
        <div className="Dashboard-Scheduler-Container">
          <Calendar className='Dashboard-Scheduler-Calendar' 
          localizer={localizer}
          events={events}
          />
        </div>
      </>

      
  )
}

export default AdminScheduler