import React, { useContext, useEffect } from 'react';
import BannerComponent from '../Components/HomePage/BannerComponent';
import InfoCards from '../Components/HomePage/InfoCards';
import MentorSlider from '../Components/HomePage/MentorSlider';
import ProjectCards from '../Components/HomePage/ProjectCards';
import Navbar from '../Components/Navbar';

import { LoginContext } from '../Context/LoginContext';
import { useNavigate } from 'react-router-dom';
import Footer from '../Components/Footer';
import { Box } from '@mui/material';


function HomePage() {

  const {isLoggedIn} = useContext(LoginContext);
  const navigate = useNavigate();


  return (
    <div className='Home-Page'>
      <Navbar />
      <BannerComponent />

      <InfoCards />
      <h3 style={{fontSize:"24px", color:"#4549ff", textAlign:"center"}}>Mentorlarımız</h3>
      <MentorSlider />
      <h3 style={{fontSize:"24px", color:"#4549ff", textAlign:"center"}}>Projelerimiz</h3>
      <ProjectCards />
      <Footer/>
    </div>
  )
}

export default HomePage