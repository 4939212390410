import React,{useContext, useState, useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import "../Style/Navbar.css"
import Logo from '../Images/Logo.png'
import MenuIcon from '@mui/icons-material/Menu';
import ProfileIcon from '../Images/HomePage/Profile.png'
import { Menu, MenuItem } from '@mui/material';

import { LoginContext } from '../Context/LoginContext';
import { jwtDecode } from 'jwt-decode';


function Navbar() {

    const [isOpen, setisOpen] = useState(false);
    const [isProfileNavOpen, setIsProfileNavOpen] = useState(false);
    const [isMentorConfirmed, setIsMentorConfirmed] = useState(true)

    const {isLoggedIn, accessToken, logout} = useContext(LoginContext);
    const navigate = useNavigate();

    const handleCloseNavMenu = () =>{
        setisOpen(false)
    }

    const handleOpenNavMenu = () => {
        setisOpen(true)
    }

    const profileOpenNavMenu = () =>{
        setIsProfileNavOpen(true)
    }

    const profileCloseNavMenu = () =>{
        setIsProfileNavOpen(false)
    }

    
    const dashboardNavigate = (e) => {
        e.preventDefault();

        if(accessToken !== null){
            const decodeToken = jwtDecode(accessToken);
            const role = decodeToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
            const isConfirmed = decodeToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication"];
            if(isConfirmed === "False"){
                setIsMentorConfirmed(false);
            }
                    if(role === "Mentor"){
                        if(isConfirmed === "True"){
                            navigate("/mentor-dashboard");
                        }
                    }
                    else if(role === "User"){
                        navigate("/mentee-dashboard");
                    }
            
        }
    }
    
    useEffect(() => {
      
    }, [])
    

  return (
    <div className='Navbar'>
        <div className="Mobile-Menu-Button">
            <button id='Mobile-Menu-Button' onClick={handleOpenNavMenu}>
                <MenuIcon />
            </button>
        </div>
        <Menu 
        id="menu-appbar"
        anchorEl={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(isOpen)}
        onClose={handleCloseNavMenu}
        style={{marginTop:"55px"}}
        >

        <MenuItem onClick={handleCloseNavMenu}><Link to="/" className="Menu-Item">Ana Sayfa</Link></MenuItem>
        <MenuItem onClick={handleCloseNavMenu}><Link to="/about-us" className="Menu-Item">Biz Kimiz</Link></MenuItem>
        <MenuItem onClick={handleCloseNavMenu}><Link to="/mentors" className="Menu-Item">Mentorlarımız</Link></MenuItem>
        <MenuItem onClick={handleCloseNavMenu}><Link to="https://www.mobimenti.co/MobiBlog/" className="Menu-Item" target='blank'>MobiBlog</Link></MenuItem>
        <MenuItem onClick={handleCloseNavMenu}><Link to="/contact-us" className="Menu-Item">Bize Ulaş</Link></MenuItem>
        </Menu>
        <div className="Logo" >
            <Link to='/'>
                <img src={Logo} alt="" />
            </Link>
            
        </div>
        <div className="Menu">
            <ul>
                <li><Link to="/" className="Menu-Item">Ana Sayfa</Link></li>
                <li><Link to="/about-us" className="Menu-Item">Biz Kimiz</Link></li>
                <li><Link to="/mentors" className="Menu-Item">Mentorlarımız</Link></li>
                <li><Link to="https://www.mobimenti.co/MobiBlog/" className="Menu-Item" target='blank'>MobiBlog</Link></li>
                <li><Link to="/contact-us" className="Menu-Item">Bize Ulaş</Link></li>

            </ul>
        </div>
        {isLoggedIn && isMentorConfirmed ? (
            <div className="Action-Buttons"
            onClick={(e) => {dashboardNavigate(e)}}
            id='action-buttons-navigate-dashboard'
            style={{
                width:"70%", 
                margin:"0 auto", 
                display:"flex", 
                alignItems:"center", 
                gap:"10px",
                border:"1px solid rgba(135, 140, 189, 0.3)",
                borderRadius:"20px",
                cursor:"pointer"
                }}>
                
                <img src={ProfileIcon} alt="" style={{width:'40px', height:'40px'}} />
                <p>Dashboard'a git</p>
            </div>
            
        ): (
            
            <div className="Action-Buttons">
                <Link to='/login'><button className='Action-Button'>Giriş Yap</button></Link>
                <Link to='/register'><button className='Action-Button'>Kaydol</button></Link>
            </div>
        )}
        
        <div className="Mobile-Action-Buttons" onClick={profileOpenNavMenu}>
            <img src={ProfileIcon} alt="" style={{width:'40px', height:'40px'}} />
        </div>
        <Menu 
            id="profile-menu-appbar"
            anchorEl={isProfileNavOpen}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(isProfileNavOpen)}
            onClose={profileCloseNavMenu}
            style={{marginTop:"55px"}}
            >
            {isLoggedIn ? (
                <MenuItem className='Mobile-Navigate-Menu' onClick={handleCloseNavMenu}>
                <Link onClick={(e) => {dashboardNavigate(e)}} className="Menu-Item" id='mobile-navigate-dashboard-btn'>Dashboard'a Git</Link>
                <Link onClick={logout} className="Menu-Item">Çıkış Yap</Link>
                </MenuItem>
            ):(
                <MenuItem className='Mobile-Navigate-Menu' onClick={handleCloseNavMenu}>
                <Link to='/login' className="Menu-Item">Giriş Yap</Link>
                <Link to='/register' className="Menu-Item">Kaydol</Link>
                </MenuItem>
            )}
            

            </Menu>
    </div>
  )
}

export default Navbar