import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material';
import { Close, Check } from '@mui/icons-material';
import { toast } from 'react-toastify';

function MeetingRequests() {
    const [requests, setRequests] = useState([]);
    const [user, setUser] = useState([]);
    const [mentor, setMentor] = useState([]);

    const columns = [
        {
          field: 'id',
          headerName: "ID",
          sortable: true,
          
        },
        {
          field: 'userId',
          headerName: "Kulanıcı",
          sortable: true,
          flex: 1,
        },
        {
          field: 'mentorId',
          headerName: "Mentor",
          sortable: true,
          flex: 1,
        },
  
        {
          field: 'status',
          headerName: "Durum",
          sortable: false,
          flex: 1,
          renderCell: (params) => (
            params.row.status !== "Bekliyor" ? 
            (<span style={{color:"green"}}>Onaylandı</span>) : 
            (
              <>
              
              <Button variant='contained' color='success' style={{margin:"0 10px"}} onClick={() => handleDelete(params.row.id)} ><Check style={{color:"white"}} /></Button>
              <Button variant='contained' color='error' onClick={() => {handleDelete(params.row.id)}} ><Close style={{color:"white"}} /></Button>
              </>
            )
          ),
        },
      ]

    const handleDelete = async (id) => {
      try {
        const response = await axios.delete(`https://server.mobimenti.co:88/api/MeetingRequest/${id}`);
        if(response.status === 200){
          toast.success("İşlem Başarılı")
        }
      } catch (error) {
        
      }
    }

      const getRequests = async () => {
        try {
            const response = await axios.get("https://server.mobimenti.co:88/api/MeetingRequest");
            const requestData = response.data;
    
            const requestsWithData = await Promise.all(requestData.map(async request => {
                const user = await getDetails(request.userId, 'User');
                const mentor = await getDetails(request.mentorId, 'Mentor');
                return {
                    ...request,
                    userId: user.name + " " + user.surname,
                    mentorId: mentor.name + " " + mentor.surname
                };
            }));
    
            setRequests(requestsWithData);

        } catch (error) {
            console.log(error);
        }
    }
    

    const getDetails = async (id, type) => { 
        try {
            const response = await axios.get(`https://server.mobimenti.co:80/api/${type}/${id}`);
            return response.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    }
    

    useEffect(() => {
      getRequests();
    }, [])
    

  return (
    <div style={{marginTop:"100px"}} className='Meeting-Request-List'>
        <h1>Görüşme Talepleri</h1>
        {requests && (
            <DataGrid
            rows={requests} 
            columns={columns} 
            style={{backgroundColor:"white", width:"100%", height:"auto"}} 
            checkboxSelection 
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 15,
                },
              },
            }}
            pageSizeOptions={[15]}
            disableRowSelectionOnClick
            />
        )}
    </div>
  )
}

export default MeetingRequests