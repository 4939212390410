import React, { useContext, useEffect, useState } from 'react'
import DashboardMeetingMiniCard from './DashboardMeetingMiniCard';
import ProfileIcon from '../../Images/Dashboard/Profile.png';
import { LoginContext } from '../../Context/LoginContext';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

function DashboardMeetingsComponent() {
  const [meetings, setMeetings] = useState([]);
  const [mentorNames, setMentorNames] = useState({})
  const [userNames, setUserNames] = useState({})
  const [role, setRole] = useState("")

  const {accessToken} = useContext(LoginContext);

  const getMeetings = async () => {
    try {
      if (accessToken) {
        const decodeToken = jwtDecode(accessToken);
        const role = decodeToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
        const userId = decodeToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];

        if(role === "User"){
          setRole("User")
          const response = await axios.get(`https://server.mobimenti.co:88/api/Meeting/GetUserMeetings/${userId}`);
          const data = await response.data;

          if (response.status === 200) {
            // Tüm mentorId'leri bir diziye al
            const mentorIds = data.map(item => item.mentorId);

            const mentorNamesObj = {};
            // Her bir mentorId için ayrı bir istek yap ve ismi mentorNames nesnesine ekle
            await Promise.all(mentorIds.map(async mentorId => {
              const mentorResponse = await axios.get(`https://server.mobimenti.co:80/api/Mentor/${mentorId}`);
              const mentorData = mentorResponse.data;
              
              mentorNamesObj[mentorId] = mentorData.name + " " +mentorData.surname;
            }));

            const currentDate = new Date();
            const filterMeeting = data.filter(item => new Date(item.meetingDate) > currentDate);

            setMentorNames(mentorNamesObj);
            setMeetings(filterMeeting);
          }
        }
        if(role === "Mentor"){
          setRole("Mentor")
          const response = await axios.get(`https://server.mobimenti.co:88/api/Meeting/GetMentorMeetings/${userId}`);
          const data = await response.data;

          if (response.status === 200) {
            const userIds = data.map(item => item.userId);
            const userNamesObj = {}

            await Promise.all(userIds.map(async userId => {
              const userResponse = await axios.get(`https://server.mobimenti.co:80/api/User/${userId}`);
              const userData = userResponse.data;
              userNamesObj[userId] = userData.name + " " +userData.surname;
            }));

            const currentDate = new Date();
            const filterMeeting = data.filter(item => new Date(item.meetingDate) > currentDate);

            setUserNames(userNamesObj);
            setMeetings(filterMeeting);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric' };
    return date.toLocaleString('tr-TR', options);
  }

  useEffect(() => {
    getMeetings();
  }, [])
  
  return (
    <div className='Dashboard-Meetings-Component'>
      <div className="Dashboard-Meetings-Component-Title">
        <p>Görüşmeler</p>
      </div>
      <div className="Dashboard-Meetings-List">
      {meetings.length > 0 ? (
        meetings.map(meeting => (
          <DashboardMeetingMiniCard
            key={meeting.id}
            image={ProfileIcon}
            name={role === "User" ? mentorNames[meeting.mentorId] : userNames[meeting.userId]}
            date={formatDate(meeting.meetingDate)}
            status="Onaylandı"
          />
        ))
      ) : (
        <p>Planlanmış bir görüşme yok</p>
      )}
      </div>
    </div>
  )
}

export default DashboardMeetingsComponent