import React, {useState, useContext, useEffect} from 'react'
import { useNavigate, Link } from 'react-router-dom';
import {Home, CalendarMonth, Chat, Settings, Close} from '@mui/icons-material';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { LoginContext } from '../../../Context/LoginContext';


import Logo from '../../../Images/Logo.png';
import LogoCycle from '../../../Images/LoginCycle.png';
import DashboardNavbar from '../DashboardNavbar';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

function MentorCalendar() {
  const [events, setEvents] = useState([])

  const navigate = useNavigate();
  const {accessToken, isLoggedIn} = useContext(LoginContext);
  const localizer = momentLocalizer(moment)

  const takeEvents = async() => {
    const decodeToken = jwtDecode(accessToken);
    const mentorId = decodeToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];

    try {
      const response = await axios.get(`https://server.mobimenti.co:88/api/Meeting/GetMentorMeetings/${mentorId}`);
      const data = await response.data;
      // Tüm mentorId'leri bir diziye al
      const userIds = data.map(item => item.userId);

      // Mentor isimlerini saklayacak olan bir nesne
      const userNames = {};

      // Her bir mentorId için ayrı bir istek yap ve ismi mentorNames nesnesine ekle
      await Promise.all(userIds.map(async userId => {
        const userResponse = await axios.get(`https://server.mobimenti.co:80/api/User/${userId}`);
        const userData = userResponse.data;
        userNames[userId] = userData.name + " " +userData.surname;
      }));

      if(data.length>0){
        const newEvent = data.map(item => ({
          start: moment(item.meetingDate).toDate(),
          end: moment(item.meetingDate).add(1, 'hour').toDate(),
          title: `${userNames[item.userId]} ile toplantı`
        }));
        setEvents(newEvent);
      }
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    takeEvents();
  }, [])
  
  return (
    <>
      <div className="Dashboard-Scheduler-Container">
        <Calendar className='Dashboard-Scheduler-Calendar' 
        localizer={localizer}
        events={events}
        />
      </div>
    </>
  )
}

export default MentorCalendar